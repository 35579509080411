import { apiWrapper } from '../helpers';
import { errorService, alertService } from '../services';
import { getKeyInStorage, removeKeyInStorage, clearStorage } from '../utils/Common';

const baseUrl = `${process.env.REACT_APP_ADMIN_API}:8001/snhcpa/v1/users/session`;

function removeUserData() {
  removeKeyInStorage('tokenUserValid');
  removeKeyInStorage('objectInfo');
  removeKeyInStorage('userValid');
  clearStorage();
  window.location.href = '/';
}

// Login
const login = (data) => {
  return apiWrapper.put(`${baseUrl}/login`, data)
}

// Reset credentials
const reset = (data) => {
  return apiWrapper.put(`${baseUrl}/reset/credentials`, data)
}
// Recover password
const recover = (data) => {
  return apiWrapper.put(`${baseUrl}/recover/password`, data)
}

// Logout
const logout = () => {
  const token = getKeyInStorage('tokenUserValid');
  const headers = {
    'session-token': token
  }
  apiWrapper.delete(`${baseUrl}/logout`, {
    headers: headers
  }).then(response => {
    removeUserData()
  })
    .catch(error => {
      if (error.response) {
        var rc = error.response.data.rc;
        var msg = error.response.data.msg;
        errorService.error(rc, msg)
      } else {
        removeUserData()
        alertService.warningSwel("Something went wrong. Please try again later.")
      }
    });
}

/* ------------------ ASYNC ----------------------------- */
//Companies
const secQuestions = () => {
  return apiWrapper.get(`${baseUrl}/security/questions`)
}

export const sessionService = {
  login,
  recover,
  reset,
  logout,
  secQuestions
};

