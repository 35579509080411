import React from 'react';
import { MainNavUser } from "../../components/header/MainNavUser";

export const ErrorView = () => {
  function reload() {
    window.location.reload();
  }
  return (
    <>
      <MainNavUser />
      <div className="diagonal-box bg-two">
        <div className="content-error">
          <span className="error-title">
            Error
          </span> <br />
          <span className="error-description h6">
            Aw, Snap!<br /><br />
            Something went wrong while displaying this webpage. To continue, reload or go to another page. Reload.
          </span><br />
          <button className="btn btn-secondary text-uppercase mt-4 font-weight-bold" onClick={reload}>Reload</button>
        </div>
      </div>
    </>
  )
}
