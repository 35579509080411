import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Navigation from '../../layout/UserLayout/Navigation';
import NavBar from '../../layout/UserLayout/NavBar';
import Card from "../../../components/MainCard";
import Aux from "../../../hoc/_Aux";
import { MainNavUser } from "../../../components/header/MainNavUser";
import { FooterUser } from "../../../components/footer/FooterUser";
import SectionButtons from "../../../components/folder/SectionButtons";
import { BreadcrumbsFiles } from "../../../components/folder/BreadcrumbsFiles";
import { TableFoldersFiles } from "../../../components/folder/TableFoldersFiles";
import { TableFiles } from "../../../components/folder/TableFiles";
import { CreateEditFolder } from "../../../components/modal/CreateEditFolder";
import { MoveFile } from "../../../components/modal/MoveFile";
import { DeleteFolder } from "../../../components/modal/DeleteFolder";
import { DeleteFile } from "../../../components/modal/DeleteFile";
import { DropZone } from "../../../components/dropzone/DropZone";
import { Loading } from "../../../components/loader/Loading";
import { getKeyInStorage } from '../../../utils/Common';
import { userService } from '../../../services';
import { MsgTimeOutError } from "../../../components/loader/MsgTimeOutError";
import '../../../assets/scss/style.scss';

export const MyDocuments = ({ history }) => {
  let title = "My documents";
  document.title = title + ' | SNHCPA';

  const [data, setData] = useState({
    userId: '',
    userRole: '',
    files: [],
    folders: [],
    filesFolder: {},
    form: {},
    typeModal: '',
    actualFolderId: '',
    actualFolderName: ''
  })
  const [allFolders, setAllFolders] = useState([]);
  const [showFiles, setShowFiles] = useState(false);
  const [userFolder, setUserFolder] = useState({
    filesId: [],
    receivedFolderId: '',
  });
  const user = getKeyInStorage('objectInfo');
  const idUser = user.userId
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showUp, setShowUp] = useState(false);
  const handleCloseUp = () => setShowUp(false);
  const handleShowUp = () => setShowUp(true);

  const [showMove, setShowMove] = useState(false);
  const handleCloseMove = () => setShowMove(false);
  const handleShowMove = () => setShowMove(true);

  const [showDeleteFolder, setShowDeleteFolder] = useState(false);
  const handleCloseDeleteFolder = () => setShowDeleteFolder(false);
  const handleShowDeleteFolder = () => setShowDeleteFolder(true);

  const [showDeleteFile, setShowDeleteFile] = useState(false);
  const handleCloseDeleteFile = () => setShowDeleteFile(false);
  const handleShowDeleteFile = () => setShowDeleteFile(true);

  const { form } = data

  useEffect(() => {
    const getUserData = async () => {
      setError(null);
      setIsLoaded(true);
      try {
        const response = await userService.userData(idUser)
        const user = response.data.data
        const data = {};
        if (user.userId)
          data["userId"] = user.userId;
        if (user.userRole.userRoleName)
          data["userRoleName"] = user.userRole.userRoleName;

        data["folders"] = [];
        data["files"] = [];
        data["typeModal"] = ''
        data["form"] = {}
        data["filesFolder"] = {}
        user.userFolders.forEach((userData) => {
          const name = userData.userFolderName
          if (name !== 'Default folder') {
            if (userData.userFolderFiles)
              data.folders.push(userData);
          } else {
            if (userData.userFolderFiles)
              data["files"] = userData
            data["actualFolderId"] = userData.userFolderId
            data["actualFolderName"] = userData.userFolderName
          }
        })
        setData(data);

      } catch (error) {
        setError(error);
        console.log(error);
      }
      setIsLoaded(false);
    }
    getUserData();
  }, [idUser]);

  return (
    <div>
      <MainNavUser />
      <Aux>
        <div className="row mx-0">
          <div className="col-sm-12 col-lg-auto p-0" >
            <Navigation />
            <NavBar />
          </div>
          <div className="col-9 mx-auto mt-5">
            {(isLoaded) ? <Loading /> : (error) ?
              <MsgTimeOutError /> :
              (
                <>
                  <div className="d-flex justify-content-end" >
                    <Button variant="link" className="btn btn-link p-0 ml-auto" onClick={() => { window.history.back(); }}><i className="feather icon-arrow-left mr-0"></i> Back</Button>
                  </div>
                  <Card title='My documents'>
                    <div className="border-bottom d-flex flex-column">
                      <SectionButtons showFiles={showFiles} setData={setData} handleShow={handleShow} handleShowUp={handleShowUp} />

                      <div className="h-100 overflow-auto">
                        <div className="py-4 position-relative">
                          <BreadcrumbsFiles data={data} setData={setData} showFiles={showFiles} setShowFiles={setShowFiles} handleShow={handleShow} handleShowUp={handleShowUp} />
                        </div>
                        {!showFiles ? (
                          <div className="documents-listing">
                            {(data.files != null && data.files.userFolderFiles?.length != null && data.files.userFolderFiles?.length > 0) ||
                              (data.folders != null && data.folders?.length != null && data.folders?.length > 0) ? (
                              <TableFoldersFiles
                                data={data}
                                setData={setData}
                                idUser={idUser}
                                setUserFolder={setUserFolder}
                                setAllFolders={setAllFolders}
                                setShowFiles={setShowFiles}
                                handleShow={handleShow}
                                handleShowMove={handleShowMove}
                                handleShowDeleteFolder={handleShowDeleteFolder}
                                handleShowDeleteFile={handleShowDeleteFile} />
                            ) : (
                              <div className="my-3 text-center">
                                <i className="feather icon-alert-triangle text-warning"> </i>
                                <span>
                                  Sorry, you have no files in this folder </span>
                              </div>)
                            }
                            <DropZone showUp={showUp} handleCloseUp={handleCloseUp} data={data} setData={setData} />
                            <div className="files files-view-list">
                            </div>
                          </div>
                        ) : (
                          <div className="documents-listing">
                            {(data.filesFolder.userFolderFiles != null && data.filesFolder.userFolderFiles?.length != null
                              && data.filesFolder.userFolderFiles?.length) > 0 ? (
                              <TableFiles
                                data={data}
                                setData={setData}
                                idUser={idUser}
                                setUserFolder={setUserFolder}
                                setAllFolders={setAllFolders}
                                handleShowMove={handleShowMove}
                                handleShowDeleteFile={handleShowDeleteFile}
                              />
                            ) : (
                              <div className="my-3 text-center">
                                <i className="feather icon-alert-triangle text-warning"> </i>
                                <span>
                                  Sorry, you have no files in this folder </span>
                              </div>)
                            }
                            <DropZone showUp={showUp} handleCloseUp={handleCloseUp} data={data} setData={setData} />
                            <div className="files files-view-list">
                            </div>
                          </div>
                        )}

                        <CreateEditFolder show={show} setShow={setShow} handleClose={handleClose} form={form} folderId={data.files.userFolderId} data={data} setData={setData} />

                        <MoveFile showMove={showMove} setShowMove={setShowMove} handleCloseMove={handleCloseMove} userFolder={userFolder} data={data} setData={setData} allFolders={allFolders} />

                        <DeleteFolder showDeleteFolder={showDeleteFolder} setShowDeleteFolder={setShowDeleteFolder} handleCloseDeleteFolder={handleCloseDeleteFolder} form={form} setData={setData} />

                        <DeleteFile showDeleteFile={showDeleteFile} setShowDeleteFile={setShowDeleteFile} handleCloseDeleteFile={handleCloseDeleteFile} data={data} form={form} setData={setData} />
                      </div>
                    </div>
                  </Card>
                </>
              )
            }
          </div>
        </div>
      </Aux>
      <FooterUser />
    </div >
  );
}