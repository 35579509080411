import React, { useState } from 'react';
import sha256 from "js-sha256";
import { Formik } from 'formik';
import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import Card from "../../components/MainCard";
import { getSchema } from "../../config/Validations";
import { sessionService, errorService, alertService } from '../../services'

export const FormSecPass = ({ email }) => {

  const [changePassword, setChangePassword] = useState({
    oldPassword: '',
    newPassword: '',
    confiPassword: '',
  });

  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfPass, setShowConfPass] = useState(false);

  const validationSchemaPass = getSchema(["oldPassword", "newPassword", "confiPassword"]);

  function onSubmitPass(values, { setSubmitting }) {
    const data = {
      email: email,
      oldPassword: sha256(values.oldPassword),
      newPassword: sha256(values.newPassword),
      confiPassword: sha256(values.confiPassword),
    };
    // CALL TO API
    sessionService.recover(data).then(response => {
      alertService.successSwel('Data successfully updated', 'Ok')
      setChangePassword({
        oldPassword: '',
        newPassword: '',
        confiPassword: ''
      })
      setSubmitting(false);
    }).catch(error => {
      setSubmitting(false);
      if (error.response) {
        var rc = error.response.data.rc;
        var msg = error.response.data.msg;
        errorService.error(rc, msg)
      } else {
        alertService.warningSwel("Something went wrong. Please try again later.")
      }
    });
  }

  return (
    <Card title='Change password'>
      <Formik initialValues={changePassword} validationSchema={validationSchemaPass} onSubmit={onSubmitPass}>
        {({ handleSubmit, handleChange, handleBlur,
          values, touched, isValid, errors, isSubmitting }) => (
          <Form noValidate onSubmit={handleSubmit} >
            <Row className="row flex-column align-content-center">
              <Col md={4}>
                <div className="form-group" id="validationFormik1">
                  <Form.Label>Old password</Form.Label>
                  <InputGroup >
                    <Form.Control
                      type={showOldPass ? "text" : "password"}
                      className={'form-control' + (errors.oldPassword && touched.oldPassword ? ' is-invalid' : '')}
                      placeholder="●●●●●●●"
                      name="oldPassword"
                      value={values.oldPassword}
                      onChange={handleChange}
                      isInvalid={!!errors.oldPassword}
                      disabled={isSubmitting}
                    />
                    <InputGroup.Text id="basic-addon2" className="p-0">
                      <span className="p-0 pointer">
                        <i id="old" className={'feather p-3 ' + (showOldPass ? ' icon-eye-off' : 'icon-eye')} onClick={() => setShowOldPass(!showOldPass)} />
                      </span>
                    </InputGroup.Text>
                    <Form.Control.Feedback type="invalid">
                      {errors.oldPassword}
                    </Form.Control.Feedback>
                  </InputGroup>
                </div>
              </Col>
              <Col md={4}>
                <div className="form-group" id="validationFormik2">
                  <Form.Label>New password</Form.Label>
                  <InputGroup >
                    <Form.Control
                      type={showNewPass ? "text" : "password"}
                      className={'form-control' + (errors.newPassword && touched.newPassword ? ' is-invalid' : '')}
                      placeholder="●●●●●●●"
                      name="newPassword"
                      value={values.newPassword}
                      onChange={handleChange}
                      isInvalid={!!errors.newPassword}
                      disabled={isSubmitting}
                    />
                    <InputGroup.Text id="basic-addon2" className="p-0">
                      <span className="p-0 pointer">
                        <i id="old" className={'feather p-3 ' + (showNewPass ? 'icon-eye-off' : 'icon-eye')} onClick={() => setShowNewPass(!showNewPass)} />
                      </span>
                    </InputGroup.Text>
                    <Form.Control.Feedback type="invalid">
                      {errors.newPassword}
                    </Form.Control.Feedback>
                  </InputGroup>
                </div>
              </Col>
              <Col md={4}>
                <div className="form-group" id="validationFormik2">
                  <Form.Label>Confirm password</Form.Label>
                  <InputGroup >
                    <Form.Control
                      type={showConfPass ? "text" : "password"}
                      className={'form-control' + (errors.confiPassword && touched.confiPassword ? ' is-invalid' : '')}
                      placeholder="●●●●●●●"
                      name="confiPassword"
                      value={values.confiPassword}
                      onChange={handleChange}
                      isInvalid={!!errors.confiPassword}
                      disabled={isSubmitting}
                    />
                    <InputGroup.Text id="basic-addon2" className="p-0">
                      <span className="p-0 pointer">
                        <i id="old" className={'feather p-3 ' + (showConfPass ? 'icon-eye-off' : 'icon-eye')} onClick={() => setShowConfPass(!showConfPass)} />
                      </span>
                    </InputGroup.Text>
                    <Form.Control.Feedback type="invalid">
                      {errors.confiPassword}
                    </Form.Control.Feedback>
                  </InputGroup>
                </div>
              </Col>
            </Row>
            <Form.Row className="mt-4">
              <Col className="text-center">
                <Button type="submit" disabled={isSubmitting} className="btn btn-primary m-0">
                  {isSubmitting ? 'Loading...' : 'Save'}
                </Button>
              </Col>
            </Form.Row>
          </Form>
        )}
      </Formik>
    </Card>
  );
}