import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Aux from "../../../hoc/_Aux";
import Navigation from '../../layout/UserLayout/Navigation';
import NavBar from '../../layout/UserLayout/NavBar';
import { getKeyInStorage } from '../../../utils/Common';
import { MainNavUser } from "../../../components/header/MainNavUser";
import { FooterUser } from "../../../components/footer/FooterUser";
import { Loading } from "../../../components/loader/Loading";
import { MsgTimeOutError } from "../../../components/loader/MsgTimeOutError";
import { Notifications } from "../../../components/Notifications";
import { userService, notificationService } from '../../../services'
import '../../../assets/scss/style.scss';

export const Dashboard = () => {

  let title = "Dashboard";
  document.title = title + ' | SNHCPA';

  const [data, setData] = useState({
    totalFolder: '',
    totalFiles: '',
    notifications: {}
  });

  var today = new Date(),
    date = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear(),
    time = today.getHours() + ':' + (today.getMinutes() < 10 ? '0' : '') + today.getMinutes();

  const state = {
    currentDate: date,
    currentTime: time,
  };
  const user = getKeyInStorage('objectInfo');
  const idUser = user.userId
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getNotifications = async () => {
      setError(null);
      setIsLoaded(true);
      try {
        const response = await notificationService.userNotifications(idUser)
        const data = response.data.data
        setData((prevState) => ({
          ...prevState,
          notifications: data
        }));
      } catch (error) {
        setError(error);
        console.log(error);
      }
      setIsLoaded(false);
    }

    const getUserData = async () => {
      setError(null);
      setIsLoaded(true);
      try {
        const response = await userService.userData(idUser)
        const user = response.data.data
        setData((prevState) => ({
          ...prevState,
          totalFolders: user.totalFolders,
          totalFiles: user.totalFiles,
        }));
      } catch (error) {
        setError(error);
        console.log(error);
      }
      setIsLoaded(false);
    }
    getNotifications()
    getUserData();
  }, [idUser]);

  return (
    <div>
      <MainNavUser />
      <Aux>
        <div className="row mx-0">
          <div className="col-sm-12 col-lg-auto p-0" >
            <Navigation />
            <NavBar />
          </div>

          <div className="col-9 mx-auto mt-5">
            {(isLoaded) ? <Loading /> : (error) ?
              <MsgTimeOutError /> :
              (
                <>
                  <div className="d-flex justify-content-end" >
                    <Button variant="link" className="btn btn-link p-0 ml-auto" onClick={() => { window.history.back(); }}><i className="feather icon-arrow-left mr-0"></i> Back</Button>
                  </div>
                  <Card className='card-event'>
                    <Card.Body>
                      <div className="row align-items-center justify-content-center">
                        <div className="col">
                          <h5 className="m-0">Welcome</h5>
                          <sub className="text-muted f-14"> to site SNHCPA.net</sub>
                        </div>
                        <div className="col-auto px-0">
                          <h5 className="text-muted mt-3 mb-0">{state.currentTime}</h5>
                        </div>
                        <div className="col-auto px-1">
                          <h5 className="text-muted mt-3 mb-0 h2">-</h5>
                        </div>
                        <div className="col-auto px-0">
                          <i className="feather icon-calendar text-info f-16" />
                          <h5 className="text-muted mt-3 mb-0">{state.currentDate}</h5>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                  <Row>
                    <Col md={6} xl={6}>
                      <Card className='card-social'>
                        <Card.Body className='border-bottom'>
                          <div className="row align-items-center justify-content-center">
                            <div className="col-auto">
                              <i className="feather icon-folder text-success f-36" />
                            </div>
                            <div className="col-auto">
                              <h5 className="text-c-purple mb-0"><span className="text-muted">Total folders</span></h5>
                            </div>
                            <div className="col text-right">
                              <h3>{data.totalFolders}</h3>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={6} xl={6}>
                      <Card className='card-social'>
                        <Card.Body className='border-bottom'>
                          <div className="row align-items-center justify-content-center">
                            <div className="col-auto">
                              <i className="feather icon-file-text text-info f-36" />
                            </div>
                            <div className="col-auto">
                              <h5 className="text-c-purple mb-0"><span className="text-muted">Total files</span></h5>
                            </div>
                            <div className="col text-right">
                              <h3>{data.totalFiles} </h3>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Notifications data={data.notifications} setData={setData} padding={'px-0'} />
                </>
              )
            }
          </div>
        </div>
      </Aux>
      <FooterUser />
    </div >
  );
}

