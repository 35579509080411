import React from 'react'
import DateView from 'react-datepicker'
import { Field, ErrorMessage } from 'formik'
import "react-datepicker/dist/react-datepicker.css";
import { addYears } from 'date-fns';

function DatePicker(props) {
  const { label, name, attr, ...rest } = props

  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <Field name={name}>
        {({ form, field, errors, touched }) => {
          const { setFieldValue } = form
          const { value } = field
          return (
            <DateView
              id={name}
              {...field}
              {...rest}
              selected={value}
              onChange={val => setFieldValue(name, val)}
              className={attr}
              maxDate={addYears(new Date(), -18)}
              minDate={addYears(new Date(), -100)}
              placeholderText="MM/DD/YYYY"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          )
        }}
      </Field>
      <ErrorMessage component="div" name={name} className="invalid-feedback-general" />
    </div>
  )
}

export default DatePicker