import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getKeyInStorage } from './Common';

// handle the public routes
export const PublicRoute = ({ logged, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => !getKeyInStorage('tokenUserValid') || !logged
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/' }} />}
    />
  )
}
