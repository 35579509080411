export default {
  items: [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/admin/dashboard',
          icon: 'feather icon-home',
        }
      ]
    },
    {
      id: 'pages',
      title: 'Pages',
      type: 'group',
      icon: 'icon-pages',
      children: [
        {
          id: 'users',
          title: 'Users',
          type: 'collapse',
          icon: 'feather icon-users',
          children: [
            {
              id: 'users-1.1',
              title: 'All users',
              type: 'item',
              url: '/admin/users/all',
            },
            {
              id: 'users-1.2',
              title: 'Assign user',
              type: 'item',
              url: '/admin/users/assign-user',
            },
            {
              id: 'users-1.3',
              title: 'Create user',
              type: 'item',
              url: '/admin/users/create',
            },
          ]
        },
        {
          id: 'companies',
          title: 'Companies',
          type: 'item',
          url: '/admin/companies',
          classes: 'nav-item',
          icon: 'feather icon-list'
        },
        // {
        //   id: 'sample-page',
        //   title: 'Sample Page',
        //   type: 'item',
        //   url: '/admin/sample-page',
        //   classes: 'nav-item',
        //   icon: 'feather icon-sidebar'
        // }
      ]
    },
    {
      id: 'configLandingPage',
      title: 'Config. Landing Page',
      type: 'group',
      icon: 'icon-monitor',
      children: [
        {
          id: 'home',
          title: 'Home',
          type: 'collapse',
          icon: 'feather icon-home',
          children: [
            {
              id: 'home-1.1',
              title: 'Main header',
              type: 'item',
              url: '/admin/config-landing/home/main-header',
            },
            {
              id: 'home-1.2',
              title: 'Sections',
              type: 'collapse',
              children: [
                {
                  id: 'home-2.1',
                  title: 'Firts section',
                  type: 'item',
                  url: '/admin/config-landing/home/firts-section',
                },
                {
                  id: 'home-2.2',
                  title: 'Second section',
                  type: 'item',
                  url: '/admin/config-landing/home/second-section',
                },
                {
                  id: 'home-2.3',
                  title: 'Third section',
                  type: 'item',
                  url: '/admin/config-landing/home/third-section',
                },
                {
                  id: 'home-2.4',
                  title: 'Fourth section',
                  type: 'item',
                  url: '/admin/config-landing/home/fourth-section',
                },

              ]
            }
          ]
        },
        {
          id: 'our-services',
          title: 'Our Services',
          type: 'collapse',
          icon: 'feather icon-award',
          children: [
            {
              id: 'our-services-1.1',
              title: 'Accounting and audit',
              type: 'collapse',
              children: [
                {
                  id: 'our-services-header-2.1',
                  title: 'Main header',
                  type: 'item',
                  url: '/admin/config-landing/services/accounting-and-audit/main-header',
                },
                {
                  id: 'our-services-sections-2.1',
                  title: 'Sections',
                  type: 'collapse',
                  children: [
                    {
                      id: 'our-services-firts-3.1',
                      title: 'Firts section',
                      type: 'item',
                      url: '/admin/config-landing/services/accounting-and-audit/firts-section',
                    },
                    {
                      id: 'our-services-second-3.2',
                      title: 'Second section',
                      type: 'item',
                      url: '/admin/config-landing/services/accounting-and-audit/second-section',
                    },
                    {
                      id: 'our-services-three-3.3',
                      title: 'Three section',
                      type: 'item',
                      url: '/admin/config-landing/services/accounting-and-audit/three-section',
                    },
                  ]
                }
              ]
            },
            {
              id: 'our-services-1.2',
              title: 'Tax',
              type: 'collapse',
              children: [
                {
                  id: 'our-services-header-2.2',
                  title: 'Main header',
                  type: 'item',
                  url: '/admin/config-landing/services/tax/main-header',
                },
                {
                  id: 'our-services-sections-2.2',
                  title: 'Sections',
                  type: 'collapse',
                  children: [
                    {
                      id: 'our-services-firts-3.1',
                      title: 'Firts section',
                      type: 'item',
                      url: '/admin/config-landing/services/tax/firts-section',
                    },
                    {
                      id: 'our-services-second-3.2',
                      title: 'Second section',
                      type: 'item',
                      url: '/admin/config-landing/services/tax/second-section',
                    },
                  ]
                }
              ]
            },
            {
              id: 'our-services-1.3',
              title: 'Outsourcing',
              type: 'collapse',
              children: [
                {
                  id: 'our-services-header-2.3',
                  title: 'Main header',
                  type: 'item',
                  url: '/admin/config-landing/services/outsourcing/main-header',
                },
                {
                  id: 'our-services-sections-2.3',
                  title: 'Sections',
                  type: 'collapse',
                  children: [
                    {
                      id: 'our-services-firts-3.1',
                      title: 'Firts section',
                      type: 'item',
                      url: '/admin/config-landing/services/outsourcing/firts-section',
                    },
                  ]
                }
              ]
            },
            {
              id: 'our-services-1.4',
              title: 'Cloud accounting services',
              type: 'collapse',
              children: [
                {
                  id: 'our-services-header-2.4',
                  title: 'Main header',
                  type: 'item',
                  url: '/admin/config-landing/services/cloud-accounting-services/main-header',
                },
                {
                  id: 'our-services-sections-2.4',
                  title: 'Sections',
                  type: 'collapse',
                  children: [
                    {
                      id: 'our-services-firts-3.1',
                      title: 'Firts section',
                      type: 'item',
                      url: '/admin/config-landing/services/cloud-accounting-services/firts-section',
                    },
                    {
                      id: 'our-services-firts-3.2',
                      title: 'Second section',
                      type: 'item',
                      url: '/admin/config-landing/services/cloud-accounting-services/second-section',
                    },
                    {
                      id: 'our-services-firts-3.3',
                      title: 'Three section',
                      type: 'item',
                      url: '/admin/config-landing/services/cloud-accounting-services/three-section',
                    },
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 'about-us',
          title: 'About us',
          type: 'collapse',
          icon: 'feather icon-book',
          children: [
            {
              id: 'about-us-1.1',
              title: 'Main header',
              type: 'item',
              url: '/admin/config-landing/about-us/main-header',
            },
            {
              id: 'about-us-1.2',
              title: 'Sections',
              type: 'collapse',
              children: [
                {
                  id: 'about-us-2.1',
                  title: 'Firts section',
                  type: 'item',
                  url: '/admin/config-landing/about-us/firts-section',
                },
                {
                  id: 'about-us-2.2',
                  title: 'Second section',
                  type: 'item',
                  url: '/admin/config-landing/about-us/second-section',
                },
              ]
            }
          ]
        },
        {
          id: 'contact-us',
          title: 'Contact us',
          type: 'collapse',
          icon: 'feather icon-phone',
          children: [
            {
              id: 'contact-us-1.1',
              title: 'Main header',
              type: 'item',
              url: '/admin/config-landing/contact-us/main-header',
            },
            {
              id: 'contact-1.2',
              title: 'Information',
              type: 'item',
              url: '/admin/config-landing/contact-us/information',
            }
          ]
        },
        {
          id: 'resources-links',
          title: 'Resources links',
          type: 'collapse',
          icon: 'feather icon-link-2',
          children: [
            {
              id: 'resources-links-1.1',
              title: 'Main header',
              type: 'item',
              url: '/admin/config-landing/resources-links/main-header',
            },
            {
              id: 'resources-links-1.2',
              title: 'Links',
              type: 'item',
              url: '/admin/config-landing/resources-links/links',
            },
          ]
        },

      ]
    },
    {
      id: 'configParameters',
      title: 'Config. Parameters',
      type: 'group',
      icon: 'icon-monitor',
      children: [
        {
          id: 'security',
          title: 'Security',
          type: 'collapse',
          icon: 'feather icon-lock',
          children: [
            {
              id: 'security-1.1',
              title: 'Attempts',
              type: 'item',
              url: '/admin/config-parameters/security/attempts',
            },
            {
              id: 'security-1.2',
              title: 'Times',
              type: 'item',
              url: '/admin/config-parameters/security/times',
            },
            {
              id: 'security-1-3',
              title: 'Questions',
              type: 'item',
              url: '/admin/config-parameters/security/questions',
              classes: 'nav-item disabled',
            },
            {
              id: 'security-1-4',
              title: 'SMTP Provider',
              type: 'item',
              url: '/admin/config-parameters/security/smtp-provider',
            },
            {
              id: 'security-1-5',
              title: 'ReCAPTCHA key',
              type: 'item',
              url: '/admin/config-parameters/security/recaptcha',
            }
          ]
        },
      ]
    }
  ],
  itemsAdmin: [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/admin/dashboard',
          icon: 'feather icon-home',
        }
      ]
    },
    {
      id: 'pages',
      title: 'Pages',
      type: 'group',
      icon: 'icon-pages',
      children: [
        {
          id: 'users',
          title: 'Users',
          type: 'collapse',
          icon: 'feather icon-users',
          children: [
            {
              id: 'users-1.1',
              title: 'All users',
              type: 'item',
              url: '/admin/users/all',
            },
            {
              id: 'users-1.2',
              title: 'Assign user',
              type: 'item',
              url: '/admin/users/assign-user',
            },
            {
              id: 'users-1.3',
              title: 'Create user',
              type: 'item',
              url: '/admin/users/create',
            },
          ]
        },
        {
          id: 'companies',
          title: 'Companies',
          type: 'item',
          url: '/admin/companies',
          classes: 'nav-item',
          icon: 'feather icon-list'
        },
        // {
        //   id: 'sample-page',
        //   title: 'Sample Page',
        //   type: 'item',
        //   url: '/admin/sample-page',
        //   classes: 'nav-item',
        //   icon: 'feather icon-sidebar'
        // }
      ]
    },
    {
      id: 'configLandingPage',
      title: 'Config. Landing Page',
      type: 'group',
      icon: 'icon-monitor',
      children: [
        {
          id: 'home',
          title: 'Home',
          type: 'collapse',
          icon: 'feather icon-home',
          children: [
            {
              id: 'home-1.1',
              title: 'Main header',
              type: 'item',
              url: '/admin/config-landing/home/main-header',
            },
            {
              id: 'home-1.2',
              title: 'Sections',
              type: 'collapse',
              children: [
                {
                  id: 'home-2.1',
                  title: 'Firts section',
                  type: 'item',
                  url: '/admin/config-landing/home/firts-section',
                },
                {
                  id: 'home-2.2',
                  title: 'Second section',
                  type: 'item',
                  url: '/admin/config-landing/home/second-section',
                },
                {
                  id: 'home-2.3',
                  title: 'Third section',
                  type: 'item',
                  url: '/admin/config-landing/home/third-section',
                },
                {
                  id: 'home-2.4',
                  title: 'Fourth section',
                  type: 'item',
                  url: '/admin/config-landing/home/fourth-section',
                },

              ]
            }
          ]
        },
        {
          id: 'our-services',
          title: 'Our Services',
          type: 'collapse',
          icon: 'feather icon-award',
          children: [
            {
              id: 'our-services-1.1',
              title: 'Accounting and audit',
              type: 'collapse',
              children: [
                {
                  id: 'our-services-header-2.1',
                  title: 'Main header',
                  type: 'item',
                  url: '/admin/config-landing/services/accounting-and-audit/main-header',
                },
                {
                  id: 'our-services-sections-2.1',
                  title: 'Sections',
                  type: 'collapse',
                  children: [
                    {
                      id: 'our-services-firts-3.1',
                      title: 'Firts section',
                      type: 'item',
                      url: '/admin/config-landing/services/accounting-and-audit/firts-section',
                    },
                    {
                      id: 'our-services-second-3.2',
                      title: 'Second section',
                      type: 'item',
                      url: '/admin/config-landing/services/accounting-and-audit/second-section',
                    },
                    {
                      id: 'our-services-three-3.3',
                      title: 'Three section',
                      type: 'item',
                      url: '/admin/config-landing/services/accounting-and-audit/three-section',
                    },
                  ]
                }
              ]
            },
            {
              id: 'our-services-1.2',
              title: 'Tax',
              type: 'collapse',
              children: [
                {
                  id: 'our-services-header-2.2',
                  title: 'Main header',
                  type: 'item',
                  url: '/admin/config-landing/services/tax/main-header',
                },
                {
                  id: 'our-services-sections-2.2',
                  title: 'Sections',
                  type: 'collapse',
                  children: [
                    {
                      id: 'our-services-firts-3.1',
                      title: 'Firts section',
                      type: 'item',
                      url: '/admin/config-landing/services/tax/firts-section',
                    },
                    {
                      id: 'our-services-second-3.2',
                      title: 'Second section',
                      type: 'item',
                      url: '/admin/config-landing/services/tax/second-section',
                    },
                  ]
                }
              ]
            },
            {
              id: 'our-services-1.3',
              title: 'Outsourcing',
              type: 'collapse',
              children: [
                {
                  id: 'our-services-header-2.3',
                  title: 'Main header',
                  type: 'item',
                  url: '/admin/config-landing/services/outsourcing/main-header',
                },
                {
                  id: 'our-services-sections-2.3',
                  title: 'Sections',
                  type: 'collapse',
                  children: [
                    {
                      id: 'our-services-firts-3.1',
                      title: 'Firts section',
                      type: 'item',
                      url: '/admin/config-landing/services/outsourcing/firts-section',
                    },
                  ]
                }
              ]
            },
            {
              id: 'our-services-1.4',
              title: 'Cloud accounting services',
              type: 'collapse',
              children: [
                {
                  id: 'our-services-header-2.4',
                  title: 'Main header',
                  type: 'item',
                  url: '/admin/config-landing/services/cloud-accounting-services/main-header',
                },
                {
                  id: 'our-services-sections-2.4',
                  title: 'Sections',
                  type: 'collapse',
                  children: [
                    {
                      id: 'our-services-firts-3.1',
                      title: 'Firts section',
                      type: 'item',
                      url: '/admin/config-landing/services/cloud-accounting-services/firts-section',
                    },
                    {
                      id: 'our-services-firts-3.2',
                      title: 'Second section',
                      type: 'item',
                      url: '/admin/config-landing/services/cloud-accounting-services/second-section',
                    },
                    {
                      id: 'our-services-firts-3.3',
                      title: 'Three section',
                      type: 'item',
                      url: '/admin/config-landing/services/cloud-accounting-services/three-section',
                    },
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 'about-us',
          title: 'About us',
          type: 'collapse',
          icon: 'feather icon-book',
          children: [
            {
              id: 'about-us-1.1',
              title: 'Main header',
              type: 'item',
              url: '/admin/config-landing/about-us/main-header',
            },
            {
              id: 'about-us-1.2',
              title: 'Sections',
              type: 'collapse',
              children: [
                {
                  id: 'about-us-2.1',
                  title: 'Firts section',
                  type: 'item',
                  url: '/admin/config-landing/about-us/firts-section',
                },
                {
                  id: 'about-us-2.2',
                  title: 'Second section',
                  type: 'item',
                  url: '/admin/config-landing/about-us/second-section',
                },
              ]
            }
          ]
        },
        {
          id: 'contact-us',
          title: 'Contact us',
          type: 'collapse',
          icon: 'feather icon-phone',
          children: [
            {
              id: 'contact-us-1.1',
              title: 'Main header',
              type: 'item',
              url: '/admin/config-landing/contact-us/main-header',
            },
            {
              id: 'contact-us-1.2',
              title: 'Information',
              type: 'item',
              url: '/admin/config-landing/contact-us/information',
            }
          ]
        },
        {
          id: 'resources-links',
          title: 'Resources links',
          type: 'collapse',
          icon: 'feather icon-link-2',
          children: [
            {
              id: 'resources-links-1.1',
              title: 'Main header',
              type: 'item',
              url: '/admin/config-landing/resources-links/main-header',
            },
            {
              id: 'resources-links-1.2',
              title: 'Links',
              type: 'item',
              url: '/admin/config-landing/resources-links/links',
            },
          ]
        },

      ]
    },
    {
      id: 'configParameters',
      title: 'Config. Parameters',
      type: 'group',
      icon: 'icon-monitor',
      children: [
        {
          id: 'security',
          title: 'Security',
          type: 'collapse',
          icon: 'feather icon-lock',
          children: [
            {
              id: 'security-1.1',
              title: 'Attempts',
              type: 'item',
              url: '/admin/config-parameters/security/attempts',
            },
            {
              id: 'security-1.2',
              title: 'Times',
              type: 'item',
              url: '/admin/config-parameters/security/times',
            },
            {
              id: 'security-1-3',
              title: 'Questions',
              type: 'item',
              url: '/admin/config-parameters/security/questions',
            },
            {
              id: 'security-1-4',
              title: 'SMTP Provider',
              type: 'item',
              url: '/admin/config-parameters/security/smtp-provider',
            },
            {
              id: 'security-1-5',
              title: 'ReCAPTCHA key',
              type: 'item',
              url: '/admin/config-parameters/security/recaptcha',
            }
          ]
        },
      ]
    }
  ],
  itemsSubAdmin: [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/admin/dashboard',
          icon: 'feather icon-home',
        }
      ]
    },
    {
      id: 'pages',
      title: 'Pages',
      type: 'group',
      icon: 'icon-pages',
      children: [
        {
          id: 'users',
          title: 'Users',
          type: 'collapse',
          icon: 'feather icon-users',
          children: [
            {
              id: 'users-1.1',
              title: 'All users',
              type: 'item',
              url: '/admin/users/all',
            },
          ]
        },
      ]
    },
    {
      id: 'security',
      title: 'Security',
      type: 'group',
      icon: 'icon-settings',
      children: [
        {
          id: 'profile',
          title: 'Profile',
          type: 'item',
          url: '/admin/profile',
          icon: 'feather icon-user',
        },
        {
          id: 'security-settings',
          title: 'Security settings',
          type: 'item',
          url: '/admin/settings',
          icon: 'feather icon-settings',
        }
      ]
    },
  ]
}