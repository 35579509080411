import React, { useState } from 'react';
import { Col, Card, Table } from 'react-bootstrap';
import { notificationService, errorService, alertService } from '../services'
import Swal from "sweetalert2";
import envelope from '../assets/images/figure/envelope.svg';
import envelopeOpen from '../assets/images/figure/envelope-open.svg';
export const Notifications = ({ data, setData, colMd, colXl, padding }) => {

  const [loadingRead, setLoadingRead] = useState({});
  const [loadingRemoved, setLoadingRemoved] = useState({});

  function readNotification(values, key) {
    setLoadingRead((loadingRead) => ({
      ...loadingRead,
      [key]: true
    }));
    // CALL TO API
    notificationService.putNotifications(values).then(response => {
      const data = response.data.data
      setData((prevState) => ({
        ...prevState,
        notifications: data
      }));
      setLoadingRead(oldLoadingRead => ({
        ...oldLoadingRead,
        [key]: false
      }))
    }).catch(error => {
      setLoadingRead(oldLoadingRead => ({
        ...oldLoadingRead,
        [key]: false
      }))
      if (error.response) {
        var rc = error.response.data.rc;
        var msg = error.response.data.msg;
        errorService.error(rc, msg)
      } else {
        alertService.warningSwel("Something went wrong. Please try again later.")
      }
    });
  }

  function deleteNotification(values, key) {
    setLoadingRemoved((loadingRemoved) => ({
      ...loadingRemoved,
      [key]: true
    }));
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F44336',
      cancelButtonColor: '#a9b7d0',
      reverseButtons: true,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        notificationService.delNotifications(values).then(response => {
          alertService.successSwel('Data successfully updated', 'Ok')
          const data = response.data.data
          setData((prevState) => ({
            ...prevState,
            notifications: data
          }));
          setLoadingRemoved(oldLoadingRemoved => ({
            ...oldLoadingRemoved,
            [key]: false
          }))
        }).catch(error => {
          setLoadingRemoved(oldLoadingRemoved => ({
            ...oldLoadingRemoved,
            [key]: false
          }))
          if (error.response) {
            var rc = error.response.data.rc;
            var msg = error.response.data.msg;
            errorService.error(rc, msg)
          } else {
            alertService.warningSwel("Something went wrong. Please try again later.")
          }
        });
      } else {
        setLoadingRemoved(oldLoadingRemoved => ({
          ...oldLoadingRemoved,
          [key]: false
        }))
      }
    })
  }

  return (
    <>
      <Col md={colMd} xl={colXl} className={"d-flex " + padding}>
        <Card className='Recent-Users w-100' style={{
          // maxHeight: 'calc(100% - 9px)',
          // overflowY: 'auto'
        }}>
          <Card.Header>
            <Card.Title as='h5' className="d-flex justify-content-between">
              <p className="mb-0">
                Notifications
              </p>
              <div className={'circle text-white ' + (data.totalUnreadNotifications > 0 ? 'bg-danger' : 'bg-success')}>
                {data.totalUnreadNotifications}
              </div>
            </Card.Title>
          </Card.Header>
          <Card.Body className='px-0 py-2'>
            {(data.notifications != null && data.notifications?.length != null
              && data.notifications?.length > 0) ? (
              <Table responsive hover>
                <tbody>
                  {data.notifications?.map((data, key) => {
                    return (
                      <tr className="unread" key={key}>
                        <td className="text-center mr-0 pr">
                          <img src={data.unread === "0" ? envelope : envelopeOpen} style={{ width: '20px' }} className="file-icon-type" alt="icon-envelope" />
                        </td>
                        <td>
                          <span className={'text-monospace mb-0' + (data.unread === "0" ? 'font-weight-bold text-primary' : '')}>{data.message} </span>
                        </td>
                        <td className="text-center">
                          <button type="button" className="btn btn-outline-success btn-sm p-1 mx-1 px-2" onClick={() => { readNotification(data.notificationId, key) }} disabled={data.unread === "1" || loadingRead[key]}>
                            <i className={'mr-0 ' + (!loadingRead[key] ? 'feather icon-check' : 'spinner-border spinner-border-sm')}></i>
                          </button>
                          <button type="button" className="btn btn-outline-danger btn-sm p-1 mx-1 px-2" onClick={() => { deleteNotification(data.notificationId, key) }} disabled={loadingRemoved[key]}>
                            <i className={'mr-0 ' + (!loadingRemoved[key] ? 'feather icon-trash-2' : 'spinner-border spinner-border-sm')}></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <div className="loader" style={{ height: '55vh' }}>
                <i className="feather icon-bell h2 text-warning" aria-hidden="true" />
                <span className="error-description h6 w-75 mx-auto">
                  Does not have any notification
                </span><br />
              </div>
            )
            }
          </Card.Body>
        </Card>
      </Col>
    </ >
  );
}

