import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import ReactLoading from 'react-loading';
import CoverPage from "../../components/CoverPage";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { MainNavUser } from "../../components/header/MainNavUser";
import { FooterUser } from "../../components/footer/FooterUser";
import { getSchema } from "../../config/Validations";
import { ErrorView } from "../../pages/error/ErrorView";
import { toolService, errorService, alertService } from '../../services'
import imgCoverPage from '../../assets/images/figure/contact-us-15o3.svg';
import '../../assets/scss/style.scss';

export const Contact = () => {

  let title = "Contact us";
  document.title = title + ' | SNHCPA';

  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const [mainHeader, setMainHeader] = useState({
    title: "",
    subtitle: "",
  });

  const [sectionOne, setSectionOne] = useState({
    title: "",
    paragraph: "",
    extraData: [{
      name: "",
      paragraph: "",
      icon: "",
      delay: ""
    }],
  });

  const validationSchema = getSchema(["name", "email", "phone", "subject", "message"]);

  function onSubmit(values, { setSubmitting }) {
    const data = {
      senderName: values.name,
      senderEmail: values.email,
      senderPhone: values.phone,
      subject: values.subject,
      message: values.message
    }
    // CALL TO API
    toolService.sendEmail(data).then(response => {
      alertService.successSwel('Thank you for contacting us, you will soon receive our response.', 'Ok')
      setSubmitting(false);
    }).catch(error => {
      setSubmitting(false);
      if (error.response) {
        var rc = error.response.data.rc;
        var msg = error.response.data.msg;
        errorService.error(rc, msg)
      } else {
        alertService.warningSwel("Something went wrong. Please try again later.")
      }
    });
  }
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const sectionMain = async () => {
      setError(null);
      setIsLoaded(true);
      try {
        const response = await toolService.getLayerSection('contact')
        const mainHeaderData = {
          layer: "CONTACT",
          title: response.data.data.mainHeader.mainHeaderTitle,
          subtitle: response.data.data.mainHeader.mainHeaderSubtitle,
        };
        setMainHeader(mainHeaderData);
        response.data.data.layerSections.forEach((section) => {
          const subsection = section.subSections[0];
          const subSection = {};
          if (subsection.subSectionTitle) {
            subSection["title"] = subsection.subSectionTitle;
          }
          if (subsection.subSectionParagraph) {
            subSection["paragraph"] = subsection.subSectionParagraph;
          }
          subSection["extraData"] = [];
          subsection.subSectionExtraData.forEach((extradata) => {
            const extraData = {};
            if (extradata.extraDataTitle) {
              extraData["name"] = extradata.extraDataTitle;
            }
            if (extradata.extraDataParagraph) {
              extraData["paragraph"] = extradata.extraDataParagraph;
            }
            if (extradata.extraDataIcon) {
              extraData["icon"] = extradata.extraDataIcon;
            }
            if (extradata.extraDataDelay) {
              extraData["delay"] = extradata.extraDataDelay;
            }
            subSection.extraData.push(extraData);
          });

          switch (section.sectionName) {
            case "SECTION_ONE":
              setSectionOne(subSection);
              break;
            default:
              break;
          }
        });
      } catch (error) {
        setError(error);
        console.log(error);
      }
      setIsLoaded(false);
    }
    sectionMain();
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <ReactLoading type={'bubbles'} color={'#112349'} height={100} width={100} className="d-flex mx-auto" />
        <h5>Loading...</h5>
      </div>
    )
  } else {
    if (error) {
      return (<ErrorView />)
    } else {
      return (
        <div>
          <MainNavUser />
          <CoverPage data={mainHeader} img={imgCoverPage} />
          <main role='main' className='flex-shrink-0 content section-bg'>
            <section className="contact py-5">
              <div className="container">
                <div className="section-title" data-aos="fade-up">
                  <h2>{sectionOne.title}</h2>
                  <p className="paragraph">{sectionOne.paragraph}</p>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      {sectionOne.extraData.map((data, key) => {
                        return (
                          <div key={key} className="col-lg-6 info" data-aos="fade-up" data-aos-delay={data.delay}>
                            <i className={"feather " + data.icon} />
                            <h4 className="my-3">{data.name}</h4>
                            {(() => {
                              switch (data.name) {
                                case 'EMAIL':
                                  return <a href={`mailto:${data.paragraph}`} className="btn-link text-primary">
                                    {data.paragraph}
                                  </a>
                                case 'PHONE':
                                case 'FAX':
                                  return <a href={`tel:${data.paragraph}`} className="btn-link text-primary">
                                    {data.paragraph}
                                  </a>
                                default:
                                  return <p>{data.paragraph}</p>
                              }
                            })()}

                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <Formik initialValues={data} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
                      {({ errors, touched, isSubmitting }) => (
                        <Form className="form-contact-us" data-aos="fade-up">
                          <div className="form-group mb-0 text-left">
                            <label>Name</label>
                            <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} placeholder="Your name" disabled={isSubmitting} />
                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                          </div>
                          <div className="form-group mb-0 text-left">
                            <label>Email</label>
                            <Field name="email" type="email" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} placeholder="example@domian.com" disabled={isSubmitting} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                          </div>
                          <div className='form-group mb-0 text-left'>
                            <label>Phone number</label>
                            <PhoneInput
                              inputClass='col-12 w-100'
                              containerClass={'form-control p-0' + (errors.phone && touched.phone ? ' is-invalid' : '')}
                              defaultCountry="us"
                              country={"us"}
                              inputProps={{
                                name: 'phone',
                              }}
                              name="phone"
                              value={data.phone}
                              onChange={(value) => setData((prevState) => ({
                                ...prevState,
                                phone: value,
                              }))}
                              disabled={isSubmitting}
                            />
                            <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                          </div>
                          <div className="form-group mb-0 text-left">
                            <label>Subject</label>
                            <Field name="subject" type="text" className={'form-control' + (errors.subject && touched.subject ? ' is-invalid' : '')} placeholder="Subject" disabled={isSubmitting} />
                            <ErrorMessage name="subject" component="div" className="invalid-feedback" />
                          </div>
                          <div className="form-group mb-0 text-left textarea-contact">
                            <label>Message</label>
                            <Field name="message" as="textarea" type="text" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} placeholder="Message" disabled={isSubmitting} />
                            <ErrorMessage name="message" component="div" className="invalid-feedback" />
                          </div>
                          <div className="mt-1 float-right">
                            <button type="submit" disabled={isSubmitting} className="btn btn-primary m-0">
                              {isSubmitting ? 'Loading...' : 'Send Message'}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>

              </div>
            </section>
          </main>
          <FooterUser />
        </div>
      );
    }
  }
}