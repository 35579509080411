import React, { useState, useEffect, useRef } from 'react';
import ReactLoading from 'react-loading';
import ReCAPTCHA from "react-google-recaptcha";
import sha256 from "js-sha256";
import PhoneInput from 'react-phone-input-2';
import { NavLink } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import Aux from "../../hoc/_Aux";
import Breadcrumb from "../../pages/layout/AdminLayout/Breadcrumb";
import RadioButtons from '../../components/RadioButtons'
import Input from '../../components/Input'
import Select from '../../components/Select'
import DatePicker from '../../components/DatePicker'
import { getKeyInStorage } from '../../utils/Common';
import { MainNavUser } from "../../components/header/MainNavUser";
import { FooterUser } from "../../components/footer/FooterUser";
import { ErrorView } from "../../pages/error/ErrorView";
import { sessionService, userService, errorService, alertService, securityService } from '../../services'
import { getSchema } from "../../config/Validations";
import '../../assets/scss/style.scss';
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-input-2/lib/style.css'

export const SignUp = ({ history }) => {

  let title = "Sign Up";
  document.title = title + ' | SNHCPA';

  const [data, setData] = useState({
    userType: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    last4SS: '',
    gender: '',
    birthday: '',
    corporationName: '',
    address: '',
    securityQuestions: [{
      questionId: "",
      answer: "",
    }],
    recaptcha: '',
  });

  const recaptchaRef = useRef(null);
  const userTypeOptions = [
    { key: 'Individual', value: 'Individual', text: 'Individual' },
    { key: 'Corporation', value: 'Corporation', text: 'Bussines' },
  ]
  const genderOptions = [
    { key: 1, value: 'M', text: 'Male' },
    { key: 2, value: 'F', text: 'Female' },
  ]
  const [questions, setQuestions] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [state, setState] = useState({ loading: false });

  const [credentials, setCredentials] = useState('6LcUK9gdAAAAAOv59stvlgQWC5ezZA7qYK-XB85U')
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const radioUserType = data.userType;
  const validationSchema = ["gender", "address"]
  const corporationName = ["corporationName"]
  const individual = ["last4SS", "birthday"]
  const corporation = radioUserType === 'Corporation' ? validationSchema.concat(corporationName) : validationSchema.concat(individual);

  const stepOneValidationSchema = getSchema(["userType", "firstName", "lastName", "email", "password", "confirmPassword"]);
  const stepTwoValidationSchema = getSchema(corporation);
  const stepThreeValidationSchema = getSchema(["securityQuestions", "recaptcha"]);
  
  const makeRequest = (formData) => {
    setState({ loading: true });
    // CALL TO API
    userService.enrollmentSignup(formData).then(response => {
      history.push('/client-portal');
      alertService.successSwel(response.data.msg)
    })
      .catch(error => {
        setState({ loading: false });
        if (error.response) {
          var rc = error.response.data.rc;
          var msg = error.response.data.msg;
          errorService.error(rc, msg)
        } else {
          alertService.warningSwel("Something went wrong. Please try again later.")
        }
      });
  };

  const handleNextStep = (newData, final = false) => {
    setData((prev) => ({ ...prev, ...newData }));
    if (final) {
      newData.password = sha256(newData.password);
      makeRequest(newData);
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  const StepOne = (props) => {
    const handleSubmit = (values) => {
      props.next(values);
    };
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShownC, setPasswordShownC] = useState(false);

    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };
    const togglePasswordVisiblityC = () => {
      setPasswordShownC(passwordShownC ? false : true);
    };
    return (
      <Formik initialValues={props.data} validationSchema={stepOneValidationSchema} onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="row d-flex align-items-center">
              <RadioButtons
                control='radio'
                label='Are you?'
                name='userType'
                options={userTypeOptions}
                attr={'custom-control-input' + (errors.userType && touched.userType ? ' is-invalid' : '')}
              />
              <div className="form-group col-6 text-left">
                <Input
                  label='First name'
                  name='firstName'
                  type="text"
                  attr={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')}
                />
              </div>
              <div className="form-group col-6 text-left">
                <Input
                  label='Last name'
                  name='lastName'
                  type="text"
                  attr={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')}
                />
              </div>
              <div className="form-group col-12 text-left">
                <Input
                  label='Email'
                  name='email'
                  type="text"
                  attr={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                />
              </div>
              <div className="form-group col-6 text-left">
                <label>Password</label>
                <div className="input-group text-left">
                  <Field name="password" type={passwordShown ? "text" : "password"} className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} placeholder="●●●●●●●" />
                  <div className="input-group-append">
                    <span className="input-group-text p-0 pointer">
                      <i className={'feather px-3 py-2 ' + (passwordShown ? ' icon-eye-off' : 'icon-eye')} onClick={togglePasswordVisiblity} />
                    </span>
                  </div>
                  <ErrorMessage name="password" component="div" className="invalid-feedback" />
                </div>
              </div>
              <div className="form-group col-6 text-left">
                <label>Confirm password</label>
                <div className="input-group text-left">
                  <Field name="confirmPassword" type={passwordShownC ? "text" : "password"} className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} placeholder="●●●●●●●" />
                  <div className="input-group-append">
                    <span className="input-group-text p-0 pointer">
                      <i className={'feather px-3 py-2 ' + (passwordShownC ? ' icon-eye-off' : 'icon-eye')} onClick={togglePasswordVisiblityC} />
                    </span>
                  </div>
                  <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary shadow-2 mb-4"
            > <span>Next</span>
            </button>
          </Form>
        )}
      </Formik>
    );
  };

  const StepTwo = (props) => {
    const [phoneAux, setPhoneAux] = useState(false);

    const handleChange = (value) => {
      setPhoneAux(false)
      if (data.phone?.length <= 0)
        setPhoneAux(true)

      props.data.phone = value
    }
    const handleSubmit = (values) => {
      if (props.data.phone?.length <= 0) {
        setPhoneAux(true)
      } else {
        values.phone = data.phone
        props.next(values);
      }
    };
    return (
      <Formik initialValues={props.data} validationSchema={stepTwoValidationSchema} onSubmit={handleSubmit}
      >
        {({ errors, touched, values }) => (
          <Form>
            <div className="row d-flex align-items-center">
              {values.userType === 'Individual' ? values.corporationName = '' : ''}
              {values.userType === 'Corporation' ?
                <div className="form-group col-12 text-left">
                  <Input
                    label="Business name"
                    name='corporationName'
                    type="text"
                    attr={'form-control' + (errors.corporationName && touched.corporationName ? ' is-invalid' : '')}
                  />
                </div>
                : ''}
              <div className='form-group text-left col-6'>
                <label>Phone number</label>
                <PhoneInput
                  inputClass='col-12 w-100'
                  containerClass={'form-control p-0' + (phoneAux ? ' is-invalid' : '')}
                  defaultCountry="us"
                  country={"us"}
                  inputProps={{
                    name: 'phone',
                    required: true,
                  }}
                  value={data.phone}
                  onChange={handleChange}
                />
                {phoneAux ? <div className="invalid-feedback">Phone is required</div> : ''}
              </div>
              {values.userType !== 'Corporation' ?
                <div className="form-group col-6 text-left">
                  <Input
                    label='Last 4 of your SS#'
                    name='last4SS'
                    type="text"
                    attr={'form-control' + (errors.last4SS && touched.last4SS ? ' is-invalid' : '')}
                  />
                </div>
                : ''}
              <div className="form-group col-6 text-left">
                <Select
                  control='select'
                  label='Gender'
                  name='gender'
                  options={genderOptions}
                  attr={'form-control' + (errors.gender && touched.gender ? ' is-invalid' : '')}
                />
              </div>
              {values.userType !== 'Corporation' ?
                <div className="form-group col-6 text-left">
                  <DatePicker
                    control='date'
                    label='Birthday'
                    name='birthday'
                    attr={'form-control' + (errors.birthday && touched.birthday ? ' is-invalid' : '')}
                  />
                </div>
                : ''}
              <div className="form-group col-12 text-left">
                <label>Address</label>
                <Field name="address" type="text" className={'form-control' + (errors.address && touched.address ? ' is-invalid' : '')} />
                <ErrorMessage name="address" component="div" className="invalid-feedback" />
              </div>
            </div>
            <button
              type="button"
              className="btn btn-secondary shadow-2 mb-4"
              onClick={() => props.prev(values)}
            > <span>Back</span>
            </button>
            <button
              type="submit"
              className="btn btn-primary shadow-2 mb-4"
            > <span>Next</span>
            </button>
          </Form>
        )}
      </Formik>
    );
  };

  const StepThree = (props) => {
    const handleSubmit = (values) => {
      props.next(values, true);
    };

    return (
      <Formik initialValues={props.data} validationSchema={stepThreeValidationSchema} onSubmit={handleSubmit}>
        {({ errors, touched, values, setFieldValue, setSubmitting }) => (
          <Form>
            <div className="row d-flex align-items-center">
              <FieldArray name="securityQuestions">
                {() => (data.securityQuestions.map((answer, i) => {
                  const securityErrors = (errors.securityQuestions?.length && errors.securityQuestions[i]) || {};
                  const securityTouched = (touched.securityQuestions?.length && touched.securityQuestions[i]) || {};
                  return (
                    <div key={i} className="col-12">
                      <div className="form-group text-left">
                        <Select
                          control='select'
                          label={`Question ${i + 1}`}
                          name={`securityQuestions.${i}.questionId`}
                          nameDef='question'
                          options={questions}
                          attr={'form-control' + (securityErrors.questionId && securityTouched.questionId ? ' is-invalid' : '')} />
                        <ErrorMessage name={`securityQuestions.${i}.questionId`} component="div" className="invalid-feedback" />
                      </div>
                      <div className="form-group text-left">
                        <label>{`Answer ${i + 1}`}</label>
                        <Field name={`securityQuestions.${i}.answer`} type="text" className={'form-control' + (securityErrors.answer && securityTouched.answer ? ' is-invalid' : '')} />
                        <ErrorMessage name={`securityQuestions.${i}.answer`} component="div" className="invalid-feedback" />
                      </div>
                    </div>
                  );
                }))}
              </FieldArray>
            </div>

            <div className="mt-2 mb-4 recaptcha text-capitalize d-flex flex-column align-items-center">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={credentials}
                onChange={(value) => {
                  setFieldValue("recaptcha", value);
                  setSubmitting(false);
                }}
                style={{ margin: 'auto' }}
              />
              <ErrorMessage name="recaptcha" component="div" className="invalid-feedback-general" />
            </div>

            <button
              type="button"
              className="btn btn-secondary shadow-2 mb-4"
              onClick={() => props.prev(values)}
            > <span>Back</span>
            </button>

            <button
              type="submit"
              className="btn btn-primary shadow-2 mb-4"
              disabled={state.loading}
            > <span>{state.loading ? 'Loading...' : 'Register'}</span>
            </button>
          </Form>
        )}
      </Formik>
    );
  };

  const steps = [
    <StepOne next={handleNextStep} data={data} />,
    <StepTwo next={handleNextStep} prev={handlePrevStep} data={data} />,
    <StepThree next={handleNextStep} prev={handlePrevStep} data={data} />
  ]

  useEffect(() => {
    if (getKeyInStorage('tokenUserValid') && getKeyInStorage('objectInfo') && getKeyInStorage('userValid')) {
      return history.push('/');
    }

    const getQuestions = async () => {
      setError(null);
      setIsLoaded(true);
      try {
        const response = await sessionService.secQuestions()
        response.data.data.securityQuestions.forEach((element) => {
          const obj = {
            key: element.questionId,
            value: element.questionId,
            text: element.question
          };
          setQuestions((prev) => [...prev, obj]);
        });
      } catch (error) {
        setError(error);
        console.log(error);
      }
      setIsLoaded(false);
    }

    const getCredentials = async () => {
      setError(null);
      setIsLoaded(true);
      try {
        const response = await securityService.getRecaptcha()
        setCredentials(response.data.data[0].recaptchaKey);
      } catch (error) {
        setError(error);
        console.log(error);
      }
      setIsLoaded(false);
    }
    getQuestions();
    getCredentials();
  }, [history]);

  if (isLoaded) {
    return (
      <div className="loader">
        <ReactLoading type={'bubbles'} color={'#112349'} height={100} width={100} className="d-flex mx-auto" />
        <h5>Loading...</h5>
      </div>
    )
  } else {
    if (error) {
      return (<ErrorView />)
    } else {
      return (
        <Aux>
          <Breadcrumb />
          <MainNavUser />
          <div className="auth-wrapper">
            <div className="auth-content" id="signUp">
              <div className="auth-bg">
                <span className="r" />
                <span className="r s" />
                <span className="r s" />
                <span className="r" />
              </div>
              <div className="card" data-aos="zoom-out">
                <div className="card-body text-center">
                  <div className="mb-4">
                    <i className="feather icon-user-plus auth-icon" />
                  </div>
                  <h3 className="mb-4">Sign up</h3>
                  {steps[currentStep]}
                  <p className="mb-0 text-muted">Already have an account? <NavLink to="/client-portal">Client portal</NavLink></p>
                </div>
              </div>
            </div>
          </div>
          <FooterUser />
        </Aux>
      );
    }
  }
}
