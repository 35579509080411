import React from 'react'
import { Button } from 'react-bootstrap';

function SectionButtons(props) {
  const { showFiles, setData, handleShow, handleShowUp } = props
  return (
    <div className="d-none d-lg-flex">
      <div className="d-flex align-items-center flex-grow-1">
        {/* <i className="feather icon-search"></i>
      <input type="text" className="form-control border-0 form-focus-none bg-transparent" placeholder="Search files, folders" /> */}
      </div>
      <div className="d-flex align-items-center">
        {!showFiles ? (
          <Button variant="light" className="btn-sm h-100 mx-2 mb-0"
            onClick={() => {
              setData((prevState) => ({
                ...prevState,
                typeModal: 'Create',
                form: ''
              })); handleShow()
            }}><i className="feather icon-plus"></i>New Folder</Button>
        ) : ''}
        <Button variant="primary" className="btn-sm h-100 mx-2 mb-0" onClick={() => { handleShowUp() }}><i className="feather icon-upload"></i> Upload</Button>
      </div>
    </div>
  )
}

export default SectionButtons

