import React from 'react';
import Card from "../../components/MainCard";

export const MsgTimeOutError = () => {
  function reload() {
    window.location.reload();
  }

  return (
    <Card>
      <div className="loader" style={{ height: '55vh' }}>
        <i className="feather icon-x-circle h2 text-danger" aria-hidden="true" />
        <h3>Error</h3>
        <span className="error-description h6 w-75 mx-auto">
          Aw, Snap!<br /><br />
          Something went wrong while displaying this webpage. To continue, reload or go to another page. Reload.
        </span><br />
        <div className="d-block">
          <button className="btn btn-primary text-uppercase font-weight-bold m-0" onClick={reload} >
            <i className="feather icon-refresh-cw" aria-hidden="true" />
            Reload</button>
        </div>
      </div>
    </Card>
  )
}
