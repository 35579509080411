import React from 'react';
import DEMO from './../../../../../store/constant';
import Aux from "../../../../../hoc/_Aux";
import { Link } from 'react-router-dom';
const navLogo = (props) => {
  let toggleClass = ['mobile-menu'];
  if (props.collapseMenu) {
    toggleClass = [...toggleClass, 'on'];
  }

  return (
    <Aux>
      <div className="navbar-brand header-logo">
        <Link to="/admin/dashboard" className="b-brand">
          <div className="b-bg">
          <i className="feather icon-user mr-1" />
          </div>
          <span className="b-title ml-2 h5 text-uppercase">Welcome</span>
        </Link>
        <a href={DEMO.BLANK_LINK} className={toggleClass.join(' ')} id="mobile-collapse" onClick={props.onToggleNavigation}><span /></a>
      </div>
    </Aux>
  );
};

export default navLogo;
