import React from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { getSchema } from "../../config/Validations";
import { folderService, errorService, alertService } from '../../services'

export const CreateEditFolder = ({ show, setShow, handleClose, form, data, setData }) => {

  const validationSchema = getSchema(["folderName"]);

  const handleChange = async event => {
    const value = event.target.value;
    event.persist();
    await setData((prevState) => ({
      ...prevState,
      form: {
        ...data.form,
        [event.target.name]: value
      }
    }));
  };

  function onSubmitAdd(values, { setSubmitting }) {
    const dataSend = {
      userId: data.userId,
      folderName: values.folderName,
    }
    folderService.addFolder(dataSend).then(response => {
      setData((prevState) => ({
        ...prevState,
        folders: []
      }));
      response.data.data.data.forEach((userData) => {
        const name = userData.userFolderName
        if (name !== 'Default folder') {

          setData((prevState) => ({
            ...prevState,
            folders: [
              ...prevState.folders,
              userData
            ]
          }))
        } else {
          setData((prevState) => ({
            ...prevState,
            files: userData
          }));
        }
      })
      setShow(false)
      setSubmitting(false);
      alertService.successSwel('Data successfully updated', 'Ok')
    }).catch(error => {
      setSubmitting(false);
      if (error.response) {
        var rc = error.response.data.rc;
        var msg = error.response.data.msg;
        errorService.error(rc, msg)
      } else {
        alertService.warningSwel("Something went wrong. Please try again later.")
      }
    });
  }

  function onSubmitUpdate(values, { setSubmitting }) {
    const data = {
      folderId: values.id,
      newFolderName: values.folderName,
    }
    folderService.updFolder(data).then(response => {
      setData((prevState) => ({
        ...prevState,
        folders: []
      }));
      response.data.data.forEach((userData) => {
        const name = userData.userFolderName
        if (name !== 'Default folder') {

          setData((prevState) => ({
            ...prevState,
            folders: [
              ...prevState.folders,
              userData
            ]
          }))
        } else {
          setData((prevState) => ({
            ...prevState,
            files: userData
          }));
        }
      })
      setShow(false)
      alertService.successSwel('Data successfully updated', 'Ok')
      setSubmitting(false);
    }).catch(error => {
      setSubmitting(false);
      if (error.response) {
        var rc = error.response.data.rc;
        var msg = error.response.data.msg;
        errorService.error(rc, msg)
      } else {
        alertService.warningSwel("Something went wrong. Please try again later.")
      }
    });
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Formik initialValues={form} validationSchema={validationSchema} onSubmit={data.typeModal === 'Create' ? onSubmitAdd : onSubmitUpdate} enableReinitialize>
          {({ handleSubmit, errors, isSubmitting }) => (
            <Form noValidate onSubmit={handleSubmit} >
              <Modal.Header closeButton>
                <Modal.Title> {data.typeModal === 'Create' ? 'Add folder' : 'Update'} </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="validationFormik1">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Folder"
                    name="folderName"
                    value={form ? form.folderName : ''}
                    onChange={handleChange}
                    isInvalid={!!errors.folderName}
                    disabled={isSubmitting}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.folderName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} disabled={isSubmitting}>
                  Close
                </Button>
                <Button type="submit" disabled={isSubmitting} className="btn btn-primary float-right m-0">
                  {isSubmitting ? 'Loading...' : 'Save'}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </ >
  );
}

