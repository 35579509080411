import React from 'react';

function CoverPage(props) {
  const { data, img } = props
  return (
    <section id="coverPage">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-start">
            <div data-aos="zoom-out">
              <h1>{data.title} </h1>
              {data.subtitle.length > 256 ?
                <p>{data.subtitle} </p> :
                <h3>{data.subtitle} </h3>}
            </div>
          </div>
          <div className="col-lg-5 order-1 order-lg-2 cover-page-img" data-aos="zoom-out" data-aos-delay="300">
            <img  src={img} className="img-fluid animated" alt="img-about-us" />
          </div>
        </div>
      </div>

      <svg className="cover-page-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
        <defs>
          <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g className="wave1">
          <use xlinkHref="#wave-path" x="50" y="3" fill="#112349" />
        </g>
        <g className="wave2">
          <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(17, 35, 73, .3)" />
        </g>
        <g className="wave3">
          <use xlinkHref="#wave-path" x="50" y="9" fill="rgba(17, 35, 73, .2)" />
        </g>
      </svg>
    </section>
  )
}
export default CoverPage;
