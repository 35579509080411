import { apiWrapper } from '../helpers';
import { errorService, alertService } from '../services';
const userUrl = `${process.env.REACT_APP_ADMIN_API}:8002/snhcpa/v1/tools`;
const adminUrl = `${process.env.REACT_APP_ADMIN_API}:8002/snhcpa/v1/tools/admin`;
const header = {
  headers: {
    "Content-Type": "application/json"
  },
  data: {}
}

//Add Extradata
const sendExtradataAdd = (data) => {
  return apiWrapper.post(`${adminUrl}/layer/extradata`, data)
}

//Add and Update Question
const sendQuestionsAdd = (data) => {
  return apiWrapper.post(`${adminUrl}/security/questions`, data)
}

// Send OTP
const sendOtp = (data) => {
  return apiWrapper.post(`${userUrl}/send/otp`, data)
}

// Send Email
const sendEmail = (data) => {
  return apiWrapper.post(`${userUrl}/send/email`, data)
}

//Update Question
const sendQuestionsUpdate = (data) => {
  return apiWrapper.put(`${adminUrl}/security/questions`, data)
}

// Send Layer
const sendLayerGeneral = (data, { setSubmitting }) => {
  apiWrapper.put(`${adminUrl}/layer`, data).then(response => {
    alertService.successSwel('Data successfully updated', 'Ok')
    setSubmitting(false);
  }).catch(error => {
    setSubmitting(false);
    if (error.response) {
      var rc = error.response.data.rc;
      var msg = error.response.data.msg;
      errorService.error(rc, msg)
    } else {
      alertService.warningSwel("Something went wrong. Please try again later.")
    }
  });
}

const sendLayer = (data) => {
  return apiWrapper.put(`${adminUrl}/layer`, data)
}

// Validate OTP
const validateOtp = (data) => {
  return apiWrapper.put(`${userUrl}/validate/otp`, data)
}

// Validate Answer
const validateAnswer = (data, { setSectionView, setSubmitting }) => {
  apiWrapper.put(`${userUrl}/validate/answer`, data).then(response => {
    setSectionView(true)
    setSubmitting(false)
    alertService.successSwel('Your answers have been verified.', 'Ok')
  })
    .catch(error => {
      setSubmitting(false);
      if (error.response) {
        var rc = error.response.data.rc;
        var msg = error.response.data.msg;
        errorService.error(rc, msg)
      } else {
        alertService.warningSwel("Something went wrong. Please try again later.")
      }
    });
}

// Delete Link
const delLink = (id) => {
  return apiWrapper.delete(`${adminUrl}/layer/extradata/` + id, header)
}

// Delete question
const delQuestion = (id) => {
  return apiWrapper.delete(`${adminUrl}/security/questions/question/` + id, header)
}

/* ------------------ ASYNC ----------------------------- */
//Layer section
const getLayerSection = async (name) => {
  return await apiWrapper.get(`${adminUrl}/layer/` + name)
}

//Layer Main Header
const getLayerMainHeader = (name) => {
  return apiWrapper.get(`${adminUrl}/layer/` + name + `?section=main-header`)
}

//Layer Section One
const getLayerSectionOne = (name) => {
  return apiWrapper.get(`${adminUrl}/layer/` + name + `?section=section_one`)
}

//Layer Section Two
const getLayerSectionTwo = (name) => {
  return apiWrapper.get(`${adminUrl}/layer/` + name + `?section=section_two`)
}

//Layer Section Three
const getLayerSectionThree = (name) => {
  return apiWrapper.get(`${adminUrl}/layer/` + name + `?section=section_three`)
}
//Layer Section Fourth
const getLayerSectionFour = (name) => {
  return apiWrapper.get(`${adminUrl}/layer/` + name + `?section=section_four`)
}

//Companies
const getAllCompanies = () => {
  return apiWrapper.get(`${adminUrl}/companies`)
}

export const toolService = {
  sendExtradataAdd,
  sendQuestionsAdd,
  sendOtp,
  sendEmail,
  sendQuestionsUpdate,
  sendLayerGeneral,
  sendLayer,
  validateOtp,
  validateAnswer,
  delLink,
  delQuestion,
  getLayerSection,
  getLayerMainHeader,
  getLayerSectionOne,
  getLayerSectionTwo,
  getLayerSectionThree,
  getLayerSectionFour,
  getAllCompanies
};




