
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import DatePicker from "react-datepicker"
import { addYears } from 'date-fns';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Row, Col, Button } from 'react-bootstrap';
import Card from "../../components/MainCard";
import { getSchema } from "../../config/Validations";
import { userService } from '../../services'

export const FormProfile = ({ data, setData }) => {

  const validationSchemaData = getSchema(["address", "phone", "birthday"]);

  function handleChange(evt) {
    const value = evt.target.value;
    setData({
      ...data,
      [evt.target.name]: value
    });
  }

  function onSubmitDetails(values, { setSubmitting }) {

    const data = {
      userId: values.userId,
      phone: values.phone,
      birthday: values.birthday,
      address: values.address,
    }
    // CALL TO API
    userService.baseUsers(data, { setSubmitting })
  }

  return (
    <Card title='Details'>
      <Formik initialValues={data} validationSchema={validationSchemaData} onSubmit={onSubmitDetails} enableReinitialize>
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div className="row mb-2">
              <div className="col-md-6">
                <div className="card mb-3 h-100">
                  <div className="card-body">
                    <div className="row border-bottom py-3">
                      <div className="col-sm-4">
                        <h6 className="mb-0 text-primary text-uppercase font-weight-bold">Full Name:</h6>
                      </div>
                      <div className="col-sm-8 text-secondary">
                        {data.userFirstname} {data.userLastname}
                      </div>
                    </div>
                    <div className="row border-bottom py-3">
                      <div className="col-sm-4">
                        <h6 className="mb-0 text-primary text-uppercase font-weight-bold">Email: </h6>
                      </div>
                      <div className="col-sm-8 text-secondary">
                        {data.userEmail}
                      </div>
                    </div>
                    <div className="row border-bottom py-3">
                      <div className="col-sm-4">
                        <h6 className="mb-0 text-primary text-uppercase font-weight-bold">Gender:</h6>
                      </div>
                      <div className="col-sm-8 text-secondary">
                        {data.userGender === 'F' ? 'Female' : 'Male'}
                      </div>
                    </div>
                    {data.userTypeName ? (<div className="row border-bottom py-3">
                      <div className="col-sm-4">
                        <h6 className="mb-0 text-primary text-uppercase font-weight-bold">Type: </h6>
                      </div>
                      <div className="col-sm-8 text-secondary">
                        {data.userTypeName}
                      </div>
                    </div>)
                      : ''
                    }
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card mb-3 h-100">
                  <div className="card-body">
                    <div className="row border-bottom py-3 align-items-center">
                      <div className="col-sm-4">
                        <h6 className="mb-0 text-primary text-uppercase font-weight-bold">Phone: </h6>
                      </div>
                      <div className="col-sm-8 text-secondary form-edit-profile">
                        <PhoneInput
                          inputClass='col-12 w-100'
                          containerClass={'form-control p-0' + (errors.phone && touched.phone ? ' is-invalid' : '')}
                          defaultCountry="us"
                          country={"us"}
                          inputProps={{
                            name: 'phone',
                          }}
                          name="phone"
                          value={data.phone}
                          onChange={(value) => setData((prevState) => ({
                            ...prevState,
                            phone: value,
                          }))}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                      </div>
                    </div>
                    <div className="row border-bottom py-3 align-items-center">
                      <div className="col-sm-4">
                        <h6 className="mb-0 text-primary text-uppercase font-weight-bold">Birthday: </h6>
                      </div>
                      <div className="col-sm-8 text-secondary form-edit-profile">
                        <DatePicker
                          id="birthday"
                          selected={data.birthday}
                          className={'form-control' + (errors.birthday && touched.birthday ? ' is-invalid' : '')}
                          name="birthday"
                          maxDate={addYears(new Date(), -18)}
                          minDate={addYears(new Date(), -100)}
                          placeholderText="MM/DD/YYYY"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="MM/dd/yyyy"
                          onChange={date => setData((prevState) => ({
                            ...prevState,
                            birthday: date,
                          }))}
                          disabled={isSubmitting}
                        />
                        <ErrorMessage component="div" name="birthday" className="invalid-feedback-general" />
                      </div>
                    </div>
                    <div className="row border-bottom py-3 align-items-center">
                      <div className="col-sm-4">
                        <h6 className="mb-0 text-primary text-uppercase font-weight-bold">Status: </h6>
                      </div>
                      <div className="col-sm-8 text-secondary">
                        <div className="col-12 text-secondary px-0">
                          {data.userStatusName}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card mb-3">
                  <div className="card-body">
                    {data.corporationName ? (
                      <>
                        <div className="row border-bottom py-3">
                          <div className="col-sm-2">
                            <h6 className="mb-0 text-primary text-uppercase font-weight-bold">Company:</h6>
                          </div>
                          <div className="col-sm-10 text-secondary">
                            {data.corporationName}
                          </div>
                        </div>
                      </>
                    ) : ''}

                    <div className="row border-bottom py-3  align-items-center">
                      <div className="col-sm-2">
                        <h6 className="mb-0 text-primary text-uppercase font-weight-bold">Last 4 SS#:</h6>
                      </div>
                      <div className="col-sm-8 text-secondary">
                        {data.userLast4SS}
                      </div>
                    </div>

                    <div className="row border-bottom py-3 align-items-center">
                      <div className="col-sm-2">
                        <h6 className="mb-0 text-primary text-uppercase font-weight-bold">Address: </h6>
                      </div>
                      <div className="col-sm-10 text-secondary form-edit-profile">
                        <Field
                          name="address"
                          type="text"
                          className={'form-control' + (errors.address && touched.address ? ' is-invalid' : '')}
                          onChange={handleChange}
                          disabled={isSubmitting} />
                        <ErrorMessage name="address" component="div" className="invalid-feedback" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Row>
              <Col>
                <Button type="submit" disabled={isSubmitting} className="btn btn-primary float-right m-0">
                  {isSubmitting ? 'Loading...' : 'Save'}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Card>
  );
}