import { UseCrypto, UseCryptoObject } from "../hooks/useCrypto";

// set the token and user from the session storage
export const setUserSession = (label = null, value = '') => {
  try {
    if (!label) throw new Error('Label not defined');
    if (label !== 'objectInfo') {
      const labelEncrypt = UseCrypto(label, 'en');
      const dataEncrypt = UseCrypto(value, 'en');
      localStorage.setItem(labelEncrypt, dataEncrypt.toString());
    } else {
      const labelObjectInfoEnc = UseCrypto(label, 'en');
      const dataObjectInfoInfoEnc = UseCryptoObject(value, 'en');
      localStorage.setItem(labelObjectInfoEnc, JSON.stringify(dataObjectInfoInfoEnc));
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

// get token and user from session storage
export const setTokenOtp = (label = null, value = '') => {
  try {
    if (!label) throw new Error('Label not defined');
    const labelEncrypt = UseCrypto(label, 'en');
    const dataEncrypt = UseCrypto(value, 'en');
    localStorage.setItem(labelEncrypt, dataEncrypt)
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

// return the data data from the session storage
export const getKeyInStorage = (label = null) => {
  try {

    if (!label) throw new Error('Label not defined');
    if (label !== 'objectInfo') {
      const labelEncrypt = UseCrypto(label, 'en');
      const dataStorage = localStorage.getItem(labelEncrypt);
      const dataDecrypt = dataStorage ? UseCrypto(dataStorage, 'de') : false;
      return dataDecrypt;
    } else {
      const labelObjEncrypt = UseCrypto(label, 'en');
      const dataObjStorage = localStorage.getItem(labelObjEncrypt);
      const dataStorageJson = JSON.parse(dataObjStorage)
      const dataObjDecrypt = dataObjStorage ? UseCryptoObject(dataStorageJson, 'de') : false;
      return dataObjDecrypt;
    }
  } catch (error) {
    console.log(error);
    return false;
  }

}

// remove the token and user from the session storage
export const removeKeyInStorage = (label = null) => {
  try {
    if (!label) throw new Error('Label not defined');
    const labelEncrypt = UseCrypto(label, 'en');
    localStorage.removeItem(labelEncrypt);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

// clear session storage
export const clearStorage = () => {
  try {
    localStorage.clear();
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
