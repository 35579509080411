import React from 'react'
import { Field, ErrorMessage } from 'formik'

function Input (props) {
  const { label, name, type, attr, ...rest } = props
  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <Field type={type} id={name} name={name} className={attr} {...rest} />
      <ErrorMessage component="div" name={name} className="invalid-feedback"/>
    </div>
  )
}

export default Input