import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import CoverPage from "../../components/CoverPage";
import { MainNavUser } from "../../components/header/MainNavUser";
import { FooterUser } from "../../components/footer/FooterUser";
import { ErrorView } from "../../pages/error/ErrorView";
import imgCoverPage from '../../assets/images/figure/walk-in-the-city.svg';
import imgHomeAllData from '../../assets/images/figure/all-the-data.svg';
import { toolService } from '../../services'
import '../../assets/scss/style.scss';

export const Home = () => {

  let title = "Home";
  document.title = title + ' | SNHCPA';

  const [mainHeader, setMainHeader] = useState({
    layer: "",
    title: "",
    subtitle: "",
  });
  const [sectionOne, setSectionOne] = useState({
    title: "",
    paragraph: "",
    extraData: [],
  });
  const [sectionTwo, setSectionTwo] = useState({
    extraData: [{
      delay: "",
      icon: "",
      title: "",
      paragraph: "",
      reference: "",
    }],
  });
  const [sectionThree, setSectionThree] = useState({
    title: "",
    paragraph: "",
    extraData: [{
      delay: "",
      link: "",
      icon: "",
      iconColor: "",
      title: "",
      paragraph: "",
    }],
  });
  const [sectionFour, setSectionFour] = useState({
    title: "",
    subtitle: "",
    paragraph: "",
    extraData: [],
  });


  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const sectionMain = async () => {
      setError(null);
      setIsLoaded(true);
      try {
        const response = await toolService.getLayerSection('home')
        const mainHeaderData = {
          layer: "HOME",
          title: response.data.data.mainHeader.mainHeaderTitle,
          subtitle: response.data.data.mainHeader.mainHeaderSubtitle,
        };
        setMainHeader(mainHeaderData);
        response.data.data.layerSections.forEach((section) => {
          const subsection = section.subSections[0];
          const subSection = {};
          if (subsection.subSectionTitle) {
            subSection["title"] = subsection.subSectionTitle;
          }
          if (subsection.subSectionSubtitle) {
            subSection["subtitle"] = subsection.subSectionSubtitle;
          }
          if (subsection.subSectionParagraph) {
            subSection["paragraph"] = subsection.subSectionParagraph;
          }
          subSection["extraData"] = [];
          subsection.subSectionExtraData.forEach((extradata) => {
            const extraData = {};
            if (extradata.extraDataDelay) {
              extraData["delay"] = extradata.extraDataDelay;
            }
            if (extradata.extraDataInternalLink) {
              extraData["link"] = extradata.extraDataInternalLink;
            }
            if (extradata.extraDataIcon) {
              extraData["icon"] = extradata.extraDataIcon;
            }
            if (extradata.extraDataIconColor) {
              extraData["iconColor"] = extradata.extraDataIconColor;
            }
            if (extradata.extraDataTitle) {
              extraData["title"] = extradata.extraDataTitle;
            }
            if (extradata.extraDataParagraph) {
              extraData["paragraph"] = extradata.extraDataParagraph;
            }
            if (extradata.extraDataReference) {
              extraData["reference"] = extradata.extraDataReference;
            }
            subSection.extraData.push(extraData);
          });

          switch (section.sectionName) {
            case "SECTION_ONE":
              setSectionOne(subSection);
              break;
            case "SECTION_TWO":
              setSectionTwo(subSection);
              break;
            case "SECTION_THREE":
              setSectionThree(subSection);
              break;
            case "SECTION_FOUR":
              setSectionFour(subSection);
              break;
            default:
              break;
          }
        });
      } catch (error) {
        setError(error);
        console.log(error);
      }
      setIsLoaded(false);
    }
    sectionMain();
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <ReactLoading type={'bubbles'} color={'#112349'} height={100} width={100} className="d-flex mx-auto" />
        <h5>Loading...</h5>
      </div>
    )
  } else {
    if (error) {
      return (<ErrorView />)
    } else {
      return (
        <div>
          <MainNavUser />
          <CoverPage data={mainHeader} img={imgCoverPage} />
          <main role='main' className='flex-shrink-0 content'>

            <section id="about" className="about section-bg">
              <div className="container aos-init aos-animate">
                <div className="section-title" data-aos="fade-up">
                  <h2>{sectionOne.title} </h2>
                </div>

                <div className="row content" data-aos="fade-up" data-aos-delay="100">
                  <div className="col-lg-12">
                    <p className="paragraph">{sectionOne.paragraph} </p>
                  </div>
                </div>
              </div>
            </section>

            <section id="summary-dashboard" className="summary-dashboard ">
              <div className="container">
                <div className="row no-gutters">
                  <div className="col-xl-7 d-flex align-items-stretch order-2 order-lg-1">
                    <div className="content d-flex flex-column justify-content-center">
                      <div className="row">
                        {sectionTwo.extraData.map((data, key) => {
                          return (
                            <div
                              key={key}
                              className="col-md-12 icon-box"
                              data-aos="fade-up"
                              data-aos-delay={data.delay}
                            >
                              <i className={"feather " + data.icon} />
                              <h4>{data.title}</h4>
                              <p className="mb-0">{data.paragraph}</p>
                              <small className="description font-italic font-bold float-right mr-4">{data.reference}</small>

                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                    <img src={imgHomeAllData} className="img-fluid animated" alt="img-home-all-the-data" />
                  </div>
                </div>
              </div>
            </section>

            <section id="services" className="services section-bg">
              <div className="container">
                <div className="section-title" data-aos="fade-up">
                  <h2>{sectionThree.title}</h2>
                  <p className="paragraph">{sectionThree.paragraph}.</p>
                </div>
                <div className="row">
                  {sectionThree.extraData.map((data, key) => {
                    return (
                      <div
                        key={key}
                        className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in"
                        data-aos-delay={"feather " + data.delay}
                      >
                        <Link to={data.link}>
                          <div className={"icon-box h-100 icon-box-" + data.iconColor}>
                            <div className="icon">
                              <i className={"feather " + data.icon} />
                            </div>
                            <h4 className="title my-2">{data.title}</h4>
                            <p className="description">{data.paragraph} </p>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>

            <section id="section-bg-img" className="section-bg-img">
              <div className="container">
                <div className="row" data-aos="zoom-in">
                  <div className="col-lg-9 text-center text-lg-left">
                    <h3 className="text-uppercase">{sectionFour.title}</h3>
                    <h4>{sectionFour.subtitle}</h4>
                    <p> {sectionFour.paragraph}</p>
                  </div>
                  <div className="col-lg-3 section-bg-img-btn-container text-center">
                    <Link className="btn btn-primary align-middle" to="/contact-us">Contact us</Link>
                  </div>
                </div>
              </div>
            </section>
          </main>
          <FooterUser />
        </div >
      );
    }
  }
}