import { apiWrapper } from '../helpers';

const baseUrl = `${process.env.REACT_APP_ADMIN_API}:8000/snhcpa/v1/folders/notifications`;
const header = {
  headers: {
    "Content-Type": "application/json"
  }
}

// Send Notifications
const sendNotifications = (data) => {
  return apiWrapper.post(`${baseUrl}`, data)
}
// Update Notifications
const putNotifications = (data) => {
  return apiWrapper.put(`${baseUrl}`, data)
}

// Delete Notifications
const delNotifications = (id) => {
  return apiWrapper.delete(`${baseUrl}/`+id)
}

/* ------------------ ASYNC ----------------------------- */
//Notifications
const userNotifications = (id) => {
  return apiWrapper.get(`${baseUrl}/users/` + id, header)
}

export const notificationService = {
  sendNotifications,
  putNotifications,
  delNotifications,
  userNotifications,
};


