import React, { Suspense } from 'react';
import { Route, Switch} from 'react-router-dom';
import Loadable from 'react-loadable';
import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "./../router/RoutesGeneral";
import { PublicRoute } from './../utils/PublicRoute';
import { PrivateRoute } from './../utils/PrivateRoute';
import { Error404View } from '../pages/error/Error404View';

const AdminLayout = Loadable({
  loader: () => import('../pages/layout/AdminLayout'),
  loading: Loader
});

export const App = () => {

  const menu = routes.map((route, index) => {
    return (route.component) ? (
      route.type === "private" ? (
        <PrivateRoute
          key={index}
          exact={route.exact}
          path={route.path}
          name={route.name}
          component={route.component}
          roles={route.roles}
          logged={route.logged}
        />
      ) : (
        <PublicRoute
          key={index}
          exact={route.exact}
          path={route.path}
          name={route.name}
          component={route.component}
          logged={route.logged}
        />
      )
    ) : (null)
  });

  return (
    <Aux>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <Switch>
              {menu}
              <PrivateRoute path="/" component={AdminLayout} logged={true}
                roles={['ADMIN', 'SUBADMIN']} />
              <Route
                path="*"
                exact={true}
                component={Error404View}
              />
          </Switch>
        </Suspense>
      </ScrollToTop>
    </Aux>
  );
}

