import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Navigation from '../../layout/UserLayout/Navigation';
import NavBar from '../../layout/UserLayout/NavBar';
import Aux from "../../../hoc/_Aux";
import { MainNavUser } from "../../../components/header/MainNavUser";
import { FooterUser } from "../../../components/footer/FooterUser";
import { Loading } from "../../../components/loader/Loading";
import { MsgTimeOutError } from "../../../components/loader/MsgTimeOutError";
import { FormSecPass } from "../../../components/form/FormSecPass";
import { FormSecQues } from "../../../components/form/FormSecQues";
import { getKeyInStorage } from '../../../utils/Common';
import { sessionService, userService } from '../../../services'
import '../../../assets/scss/style.scss';

export const SecuritySettings = () => {

  let title = "Security settings";
  document.title = title + ' | SNHCPA';

  const [questions, setQuestions] = useState([]);
  const [questionUser, setQuestionUser] = useState({
    changeSecurityQuestions: [{
      question: {
        questionId: '',
        oldQuestionId: '',
        question: ''
      },
      answer: '',
    }]
  });

  const user = getKeyInStorage('objectInfo');
  const email = getKeyInStorage('userValid');
  const userId = user.userId

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getQuestions = async () => {
      setError(null);
      setIsLoaded(true);
      try {
        const response = await sessionService.secQuestions()
        response.data.data.securityQuestions.forEach((element) => {
          const obj = {
            key: element.questionId,
            value: element.questionId,
            text: element.question
          };
          setQuestions((prev) => [...prev, obj]);
        });
      } catch (error) {
        setError(error);
        console.log(error);
      }
      setIsLoaded(false);
    }

    const getQnsUser = async () => {
      setError(null);
      setIsLoaded(true);
      try {
        const response = await userService.emailSecSettings(email)
        response.data.data.userSecurityQuestions.forEach((element) => {
          const changeSecurityQuestions = [];
          const data = {
            question: {
              questionId: element.question.questionId,
              oldQuestionId: element.question.questionId,
              question: element.question.question
            },
            answer: element.answer
          }
          changeSecurityQuestions.push(data);
          setQuestionUser((prevState) => ({
            ...prevState,
            changeSecurityQuestions: changeSecurityQuestions
          }));
        });
      } catch (error) {
        setError(error);
        console.log(error);
      }
      setIsLoaded(false);
    }
    getQuestions();
    getQnsUser();
  }, [email]);

  return (
    <div>
      <MainNavUser />
      <Aux>
        <div className="row mx-0">
          <div className="col-sm-12 col-lg-auto p-0" >
            <Navigation />
            <NavBar />
          </div>

          <div className="col-9 mx-auto mt-5">
            {(isLoaded) ? <Loading /> : (error) ?
              <MsgTimeOutError /> :
              (
                <>
                  <div className="d-flex justify-content-end" >
                    <Button variant="link" className="btn btn-link p-0 ml-auto" onClick={() => { window.history.back(); }}><i className="feather icon-arrow-left mr-0"></i> Back</Button>
                  </div>
                  <FormSecPass email={email} />
                  <FormSecQues
                    userId={userId}
                    questions={questions}
                    questionUser={questionUser}
                    setQuestionUser={setQuestionUser}
                  />
                </>
              )
            }
          </div>
        </div>
      </Aux>
      <FooterUser />
    </div >
  );
}