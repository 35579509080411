import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { errorService, alertService, folderService } from '../../services'

export const DeleteFile = ({ showDeleteFile, setShowDeleteFile, handleCloseDeleteFile, form, data, setData }) => {

  const [state, setState] = useState({
    loading: false,
  });
  const folderId = data.actualFolderId
  function onSubmitDelete() {
    const fileId = form.id
    setState({ loading: true });
    folderService.delFile(fileId).then(response => {
      if (Object.keys(data.filesFolder).length === 0) {
        response.data.data.forEach((userData) => {
          const name = userData.userFolderName
          if (name === 'Default folder') {
            setData((prevState) => ({
              ...prevState,
              files: {
                ...prevState.files,
                userFolderFiles: userData.userFolderFiles
              },
            }))
          }
        })
      } else {
        setData((prevState) => ({
          ...prevState,
          filesFolder: {
            ...prevState.filesFolder,
            userFolderFiles: response.data.data
          },
        }))
        data.folders.forEach((userData, index) => {
          if (userData.userFolderId === folderId) {
            let folders = [...data.folders];
            folders[index] = { ...folders[index], userFolderFiles: response.data.data };
            setData((prevState) => ({
              ...prevState,
              folders: folders
            }))
          }
        })
      }
      setShowDeleteFile(false)
      alertService.successSwel('Data successfully updated', 'Ok')
      setState({ loading: false });
    }).catch(error => {
      setState({ loading: false });
      if (error.response) {
        var rc = error.response.data.rc;
        var msg = error.response.data.msg;
        errorService.error(rc, msg)
      } else {
        alertService.warningSwel("Something went wrong. Please try again later.")
      }
    });
  }

  return (
    <>
      <Modal show={showDeleteFile} onHide={handleCloseDeleteFile}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Are you sure you want to delete the <b>"{form && form.fileName}"</b>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteFile}>
            No
          </Button>
          <Button type="button" variant="danger" disabled={state.loading} onClick={onSubmitDelete}>
            {state.loading ? 'Loading...' : 'Yes'}
          </Button>
        </Modal.Footer>
      </Modal>
    </ >
  );
}

