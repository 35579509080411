import { apiWrapper } from '../helpers';
import { errorService, alertService } from '../services';

const baseUrl = `${process.env.REACT_APP_ADMIN_API}:8002/snhcpa/v1/tools/security/credentials`;

//Update rules
const sendRules = (data, { setSubmitting }) => {
  apiWrapper.put(`${baseUrl}/rules`, data).then(response => {
    alertService.successSwel('Data successfully updated', 'Ok')
    setSubmitting(false);
  }).catch(error => {
    setSubmitting(false);
    if (error.response) {
      var rc = error.response.data.rc;
      var msg = error.response.data.msg;
      errorService.error(rc, msg)
    } else {
      alertService.warningSwel("Something went wrong. Please try again later.")
    }
  });
}

//Update SMTP
const sendSmtp = (data, { setSubmitting }) => {
  apiWrapper.put(`${baseUrl}/smtp`, data).then(response => {
    alertService.successSwel('Data successfully updated', 'Ok')
    setSubmitting(false);
  }).catch(error => {
    setSubmitting(false);
    if (error.response) {
      var rc = error.response.data.rc;
      var msg = error.response.data.msg;
      errorService.error(rc, msg)
    } else {
      alertService.warningSwel("Something went wrong. Please try again later.")
    }
  });
}

//Update Recaptcha
const sendRecaptcha = (data, { setSubmitting }) => {
  apiWrapper.put(`${baseUrl}/recaptcha`, data).then(response => {
    alertService.successSwel('Data successfully updated', 'Ok')
    setSubmitting(false);
  }).catch(error => {
    setSubmitting(false);
    if (error.response) {
      var rc = error.response.data.rc;
      var msg = error.response.data.msg;
      errorService.error(rc, msg)
    } else {
      alertService.warningSwel("Something went wrong. Please try again later.")
    }
  });
}

/* ------------------ ASYNC ----------------------------- */
//Rules
const getRules = () => {
  return apiWrapper.get(`${baseUrl}/rules`)
}

//SMTP
const getSmtp = () => {
  return apiWrapper.get(`${baseUrl}/smtp`)
}

//ReCAPTCHA
const getRecaptcha = () => {
  return apiWrapper.get(`${baseUrl}/recaptcha`)
}

export const securityService = {
  sendRules,
  sendSmtp,
  sendRecaptcha,
  getRules,
  getSmtp,
  getRecaptcha
};




