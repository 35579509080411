import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import CoverPage from "../../components/CoverPage";
import { MainNavUser } from "../../components/header/MainNavUser";
import { FooterUser } from "../../components/footer/FooterUser";
import { ErrorView } from "../../pages/error/ErrorView";
import imgCoverPage from '../../assets/images/figure/finance-analytics.svg';
import imgTaxRероrtіng from '../../assets/images/figure/tax-rероrtіng.svg';
import imgTaxTeamSpirit from '../../assets/images/figure/team-spirit-re.svg';
import imgTaxSecure from '../../assets/images/figure/secure-login.svg';
import imgTaxGrowth from '../../assets/images/figure/growth-analytics.svg';
import imgTaxWorking from '../../assets/images/figure/working.svg';
import imgTaxAgreement from '../../assets/images/figure/agreement.svg';
import { toolService } from '../../services'
import '../../assets/scss/style.scss';

export const Tax = () => {

  let title = "Tax - Our Services";
  document.title = title + ' | SNHCPA';

  const [mainHeader, setMainHeader] = useState({
    layer: "",
    title: "",
    subtitle: "",
  });
  const [sectionOne, setSectionOne] = useState({
    paragraph: "",
    extraData: [{
      title: "",
    }],
  });
  const [sectionOneExtra, setSectionOneExtra] = useState({
    paragraph: "",
  });
  const [sectionTwo, setSectionTwo] = useState({
    extraData: [{
      delay: "",
      color: "",
      title: "",
      paragraph: "",
    }],
  });

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const sectionMain = async () => {
      setError(null);
      setIsLoaded(true);
      try {
        const response = await toolService.getLayerSection('tax')
        const mainHeaderData = {
          layer: "TAX",
          title: response.data.data.mainHeader.mainHeaderTitle,
          subtitle: response.data.data.mainHeader.mainHeaderSubtitle,
        };
        setMainHeader(mainHeaderData);
        response.data.data.layerSections.forEach((section) => {
          const subsection = section.subSections[0];
          const subSection = {};
          if (subsection.subSectionTitle) {
            subSection["title"] = subsection.subSectionTitle;
          }
          if (subsection.subSectionSubtitle) {
            subSection["subtitle"] = subsection.subSectionSubtitle;
          }
          if (subsection.subSectionParagraph) {
            subSection["paragraph"] = subsection.subSectionParagraph;
          }
          subSection["extraData"] = [];
          subsection.subSectionExtraData.forEach((extradata) => {
            const extraData = {};
            if (extradata.extraDataDelay) {
              extraData["delay"] = extradata.extraDataDelay;
            }
            if (extradata.extraDataIconColor) {
              extraData["color"] = extradata.extraDataIconColor;
            }
            if (extradata.extraDataTitle) {
              extraData["title"] = extradata.extraDataTitle;
            }
            if (extradata.extraDataParagraph) {
              extraData["paragraph"] = extradata.extraDataParagraph;
            }
            subSection.extraData.push(extraData);
          });

          if (section.subSections[1]) {
            const subsectionExtra = section.subSections[1];
            const subSectionExtra = {};
            if (subsectionExtra.subSectionParagraph) {
              subSectionExtra["paragraph"] = subsectionExtra.subSectionParagraph;
            }
            setSectionOneExtra(subSectionExtra);
          }

          switch (section.sectionName) {
            case "SECTION_ONE":
              setSectionOne(subSection);
              break;
            case "SECTION_TWO":
              setSectionTwo(subSection);
              break;
            default:
              break;
          }
        });
      } catch (error) {
        setError(error);
      }
      setIsLoaded(false);
    }
    sectionMain();
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <ReactLoading type={'bubbles'} color={'#112349'} height={100} width={100} className="d-flex mx-auto" />
        <h5>Loading...</h5>
      </div>
    )
  } else {
    if (error) {
      return (<ErrorView />)
    } else {
      return (
        <div>
          <MainNavUser />
          <CoverPage data={mainHeader} img={imgCoverPage} />

          <main role='main' className='flex-shrink-0 content'>
            <section id="about" className="about section-bg">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 my-auto" data-aos="zoom-in">
                    <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                    <img src={imgTaxRероrtіng} className="img-fluid animated" alt="img-tax-reporting" />

                  </div>
                  <div className="col-lg-6 d-flex flex-column justify-contents-center" data-aos="fade-left">
                    <div className="content pt-4 pt-lg-0">
                      <p className="paragraph">{sectionOne.paragraph} </p>
                      <ul>
                        {sectionOne.extraData.map((data, key) => {
                          return (
                            <li key={key}><i className="feather icon-check"></i>{data.title}</li>
                          );
                        })}
                      </ul>
                      <p className="paragraph">{sectionOneExtra.paragraph} </p>
                    </div>
                  </div>
                </div>

              </div>
            </section>
            <section id="details" className="details">
              <div className="container">
                <div className="row content">
                  <div className="col-md-4 order-1 order-md-2" data-aos="fade-left">
                    <img src={imgTaxTeamSpirit} className="img-fluid animated" alt="img-tax-team-spirit" />
                  </div>
                  <div className="col-md-8 pt-5 order-2 order-md-1" data-aos="fade-up">
                    <h3>{sectionTwo.extraData[0]?.title}</h3>
                    <p className="paragraph">{sectionTwo.extraData[0]?.paragraph} </p>
                  </div>
                </div>

                <div className="row content">
                  <div className="col-md-4" data-aos="fade-right">
                    <img src={imgTaxSecure} className="img-fluid animated" alt="img-tax-secure" />
                  </div>
                  <div className="col-md-8 pt-5" data-aos="fade-up">
                    <h3>{sectionTwo.extraData[1]?.title}</h3>
                    <p className="paragraph">{sectionTwo.extraData[1]?.paragraph} </p>
                  </div>
                </div>

                <div className="row content">
                  <div className="col-md-8 pt-5" data-aos="fade-up">
                    <h3>{sectionTwo.extraData[2]?.title}</h3>
                    <p className="paragraph">{sectionTwo.extraData[2]?.paragraph} </p>
                  </div>
                  <div className="col-md-4" data-aos="fade-right">
                    <img src={imgTaxGrowth} className="img-fluid animated" alt="img-tax-growth-analytics" />
                  </div>
                </div>

                <div className="row content">
                  <div className="col-md-4" data-aos="fade-right">
                    <img src={imgTaxWorking} className="img-fluid animated" alt="img-tax-working" />
                  </div>
                  <div className="col-md-8 pt-5" data-aos="fade-up">
                    <h3>{sectionTwo.extraData[3]?.title}</h3>
                    <p className="paragraph">{sectionTwo.extraData[3]?.paragraph} </p>
                  </div>
                </div>

                <div className="row content">
                  <div className="col-md-8 pt-5" data-aos="fade-up">
                    <h3>{sectionTwo.extraData[4]?.title}</h3>
                    <p className="paragraph">{sectionTwo.extraData[4]?.paragraph} </p>
                  </div>
                  <div className="col-md-4" data-aos="fade-right">
                    <img src={imgTaxAgreement} className="img-fluid animated" alt="img-tax-agreement" />
                  </div>
                </div>
              </div>
            </section>
          </main>
          <FooterUser />
        </div>
      );
    }
  }
}
