import React, { useRef, useEffect, useState } from 'react';
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import ReactLoading from 'react-loading';
import { NavLink } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Aux from "../../hoc/_Aux";
import Breadcrumb from "../../pages/layout/AdminLayout/Breadcrumb";
import { getKeyInStorage } from '../../utils/Common';
import { MainNavUser } from "../../components/header/MainNavUser";
import { FooterUser } from "../../components/footer/FooterUser";
import { getSchema } from "../../config/Validations";
import { setTokenOtp } from '../../utils/Common';
import { ErrorView } from "../../pages/error/ErrorView";
import { errorService, toolService, alertService, securityService } from '../../services'
import '../../assets/scss/style.scss';

export const ForgotPassword = ({ history }) => {

  let title = "Forgot password";
  document.title = title + ' | SNHCPA';

  const initialValues = {
    email: '',
    recaptcha: '',
  };

  const [credentials, setCredentials] = useState('6LcUK9gdAAAAAOv59stvlgQWC5ezZA7qYK-XB85U')
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const recaptchaRef = useRef(null);
  const validationSchema = getSchema(["email", "recaptcha"]);

  const tokenOtp = (flow, privateToken, userValid) => {
    setTokenOtp('route', flow);
    setTokenOtp('tokenValid', privateToken);
    setTokenOtp('userValid', userValid);
  };

  function onSubmit({ email }, { setSubmitting }) {
    const data = {
      email: email,
      flow: "RECOVER_PASSWORD"
    };
    // CALL TO API
    toolService.sendOtp(data).then(response => {
      const privateToken = response.data.data.privateToken;
      const userEmail = email;
      const flow = 'RECOVER_PASSWORD';
      tokenOtp(flow, privateToken, userEmail)
      alertService.successConfirmSwel()
      history.push('/otp');
    })
      .catch(error => {
        if (error.response) {
          setSubmitting(false);
          if (error.response) {
            var rc = error.response.data.rc;
            var msg = error.response.data.msg;
            if (rc === '-2') {
              const dataOtp = {
                email: email,
                flow: 'VALIDATE_USER'
              };
              Swal.fire({
                icon: 'info',
                title: 'Information',
                text: error.response.data.msg,
                confirmButtonText: 'Validate',
                confirmButtonColor: '#112349',
                focusConfirm: false,
                showLoaderOnConfirm: true,
                // CALL TO API
                preConfirm: () => {
                  return toolService.sendOtp(dataOtp)
                    .then(response => {
                      const privateToken = response.data.data.privateToken;
                      const userEmail = email;
                      const flow = 'VALIDATE_USER';
                      tokenOtp(flow, privateToken, userEmail)
                      alertService.successConfirmSwel()
                      history.push('/otp');
                    })
                    .catch(error => {
                      console.log('error');
                    })
                },
                allowOutsideClick: () => !Swal.isLoading()
              })
            } else {
              errorService.error(rc, msg)
            }
          } else {
            alertService.warningSwel("Something went wrong. Please try again later.")
          }
        }
      });
  }

  useEffect(() => {
    if (getKeyInStorage('tokenUserValid') &&
      getKeyInStorage('objectInfo') &&
      getKeyInStorage('userValid')) {
      return history.push('/')
    }

    const getCredentials = async () => {
      setError(null);
      setIsLoaded(true);
      try {
        const response = await securityService.getRecaptcha()
        setCredentials(response.data.data[0].recaptchaKey);
      } catch (error) {
        setError(error);
        console.log(error);
      }
      setIsLoaded(false);
    }
    getCredentials();
  }, [history])

  if (isLoaded) {
    return (
      <div className="loader">
        <ReactLoading type={'bubbles'} color={'#112349'} height={100} width={100} className="d-flex mx-auto" />
        <h5>Loading...</h5>
      </div>
    )
  } else {
    if (error) {
      return (<ErrorView />)
    } else {
      return (
        <Aux>
          <Breadcrumb />
          <MainNavUser />
          <div className="auth-wrapper">
            <div className="auth-content">
              <div className="auth-bg">
                <span className="r" />
                <span className="r s" />
                <span className="r s" />
                <span className="r" />
              </div>
              <div className="card" data-aos="zoom-out">
                <div className="card-body text-center">
                  <div className="mb-4">
                    <i className="feather icon-unlock auth-icon" />
                  </div>
                  <h3 className="mb-4">Forgot password?</h3>
                  <p className="py-2">Dont't worry! Enter your email below and we'll email you with instructions on how to reset your password.</p>
                  <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ errors, touched, isSubmitting, setFieldValue, setSubmitting }) => (
                      <Form>
                        <div className="form-group text-left">
                          <label>Email</label>
                          <Field name="email" type="email" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} placeholder="example@domian.com" disabled={isSubmitting} />
                          <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </div>
                        <div className="mt-2 mb-4 recaptcha text-capitalize d-flex flex-column align-items-center">
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={credentials}
                            onChange={(value) => {
                              setFieldValue("recaptcha", value);
                              setSubmitting(false);
                            }}
                          />
                          <ErrorMessage name="recaptcha" component="div" className="invalid-feedback-general" />
                        </div>
                        <div className="form-row py-2">
                          <div className="col">
                            <button type="submit" disabled={isSubmitting} className="btn btn-primary mb-4">
                              {isSubmitting ? 'Loading...' : 'Send'}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <p className="mb-0 text-muted">Already a user? <NavLink to="/client-portal">Client portal</NavLink></p>
                </div>
              </div>
            </div>
          </div>
          <FooterUser />
        </Aux>
      );
    }
  }
}