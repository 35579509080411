import React from 'react'
import { Field, ErrorMessage } from 'formik'

function Select(props) {
  const { label, name, nameDef, options, attr, ...rest } = props
  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <Field as='select' id={name} name={name} {...rest} className={attr} >
        <option value="">Select {nameDef} </option>
        {options.map(option => {
          return (
            <option key={option.key} value={option.value}>
              {option.text}
            </option>
          )
        })}
      </Field>
      <ErrorMessage component="div" name={name} className="invalid-feedback" />
    </div>
  )
}

export default Select