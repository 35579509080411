import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { MainNavInfo } from "../header/MainNavInfo";
import { getKeyInStorage } from '../../utils/Common';
import { sessionService } from '../../services'
import logo from '../../assets/images/logo/logo-dark-snc.svg';
import icon from '../../assets/images/logo/logo-icon-dark.svg';
import '../../assets/scss/style.scss';

export const MainNavUser = () => {
  const state = {
    logo_link: '/',
    home: 'Home',
    ourServices: 'Our Services',
    ourServItems: {
      item1: 'Accounting and audit',
      item2: 'Tax',
      item3: 'Outsourcing',
      item4: 'Cloud Accounting Services',
    },
    about: 'About us',
    contact: 'Contact us',
    resourcesLinks: 'Resources & Links',
    clientPortal: 'Client portal',
    dashboard: 'Dashboard',
    settings: 'Settings',
    logout: 'Logout',
  }

  const [mQuery, setMQuery] = useState({
    matches: window.innerWidth > 480 ? true : false,
  });
  const [locationServ, setLocationServ] = useState(false)
  const [locationDash, setLocationDash] = useState(false)
  const user = getKeyInStorage('objectInfo');

  function handleLogout() {
    // CALL TO API
    sessionService.logout()
  }

  useEffect(() => {
    let mediaQuery = window.matchMedia("(min-width: 480px)");
    mediaQuery.addListener(setMQuery);
    function pathnameServices() {
      if (window.location.pathname === '/services/accounting-and-audit' ||
        window.location.pathname === '/services/tax' ||
        window.location.pathname === '/services/outsourcing' ||
        window.location.pathname === '/services/cloud-accounting-services') {
        setLocationServ(true)
      } else {
        setLocationServ(false)
      }
    }
    function pathnameDashboard() {
      if (window.location.pathname === '/dashboard' ||
        window.location.pathname === '/my-documents' ||
        window.location.pathname === '/my-documents/folder' ||
        window.location.pathname === '/profile' ||
        window.location.pathname === '/security-settings') {
        setLocationDash(true)
      } else {
        setLocationDash(false)
      }
    }
    pathnameServices();
    pathnameDashboard();
    return () => mediaQuery.removeListener(setMQuery);
  }, []);

  return (
    <>
      <header className="main-head">
        <Navbar id="navUser" collapseOnSelect expand="lg" className="bg-c-blue navbar navbar-dark" >
          <Container className="d-flex align-items-center">
            <Navbar.Brand>
              <a className="logo_link mr-auto" href={state.logo_link}>
                <div className="">
                  <div >
                    {mQuery.matches && (<img className="nav_logo" src={logo} alt="Generic placeholder" />)}
                    {!mQuery.matches && (<img className="nav_logo" src={icon} alt="Generic placeholder" />
                    )}
                  </div>
                </div>
              </a>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="navbar-nav ml-auto text-uppercase">
                <Nav.Link as={NavLink} exact to="/" className="home">
                  {state.home}
                </Nav.Link>
                <NavDropdown
                  id="nav-dropdown-dark-example"
                  title={state.ourServices}
                  className={'mx-2' + (locationServ ? ' active' : '')}
                  href="/services"
                >
                  <NavDropdown.Item as={NavLink} exact to="/services/accounting-and-audit">{state.ourServItems.item1} </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} exact to="/services/tax">{state.ourServItems.item2} </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} exact to="/services/outsourcing">{state.ourServItems.item3} </NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} exact to="/services/cloud-accounting-services">{state.ourServItems.item4} </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={NavLink} exact to="/about-us">{state.about}</Nav.Link>
                <Nav.Link as={NavLink} exact to="/contact-us">{state.contact}</Nav.Link>
                <Nav.Link as={NavLink} exact to="/resources-links">{state.resourcesLinks}</Nav.Link>
                {user.userName ? (
                  <>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title={
                        <>
                          <i className="feather icon-user mr-1" />
                          {user.userRole !== 'ADMIN' ? user.userName : ' Admin'}
                        </>
                      }
                      className={'mx-2' + (locationDash ? ' active' : '')}
                      href="/dashboard">
                      {user.userRole === 'USER' ?
                        <>
                          <NavDropdown.Item as={NavLink} exact to="/dashboard"><i className="feather icon-home" />{state.dashboard} </NavDropdown.Item>
                          <NavDropdown.Item as={NavLink} exact to="/security-settings"><i className="feather icon-settings" /> {state.settings} </NavDropdown.Item>
                        </>
                        :
                        <NavDropdown.Item as={NavLink} exact to="/admin/dashboard"><i className="feather icon-home" />{state.dashboard} </NavDropdown.Item>
                      }
                      <span className="dropdown-item" onClick={handleLogout} style={{ cursor: 'pointer' }}><i className="feather icon-log-out"></i> Logout</span>
                    </NavDropdown>
                  </>
                ) : (
                  <Nav.Link as={NavLink} exact to="/client-portal" className="btn btn-secondary p-2 ml-2 mb-0">
                    {state.clientPortal}
                  </Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <MainNavInfo />
      </header>
    </>
  );
}