import React, { useState, useEffect } from 'react';
import sha256 from 'js-sha256';
import ReactLoading from 'react-loading';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import Aux from '../../hoc/_Aux';
import Breadcrumb from '../../pages/layout/AdminLayout/Breadcrumb';
import { getSchema } from '../../config/Validations';
import { ErrorView } from '../../pages/error/ErrorView';
import { getKeyInStorage, removeKeyInStorage } from '../../utils/Common';
import { sessionService, userService, toolService, errorService, alertService } from '../../services'
import '../../assets/scss/style.scss';

export const ResetPassword = ({ history }) => {

  let title = 'Forgot password';
  document.title = title + ' | SNHCPA';

  const [initialValues] = useState({
    answers: [],
    password: '',
    confirmPassword: '',
  });

  const email = getKeyInStorage('userValid');
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [sectionView, setSectionView] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShownC, setPasswordShownC] = useState(false);
  const validationSchemaQuestions = getSchema(["answers"]);
  const validationSchemaPassword = getSchema(["password", "confirmPassword"]);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const togglePasswordVisiblityC = () => {
    setPasswordShownC(passwordShownC ? false : true);
  };

  const remove = () => {
    removeKeyInStorage('route');
    removeKeyInStorage('tokenValid');
  };

  function onSubmitAnswers(fields, { setSubmitting }) {
    const data = {
      email: email,
      answers: fields.answers,
    };
    // CALL TO API
    toolService.validateAnswer(data, { setSubmitting, setSectionView })
  }

  function onSubmitPassword(fields, { setSubmitting }) {
    const data = {
      email: email,
      newPassword: sha256(fields.password)
    };
    // CALL TO API
    sessionService.recover(data).then(response => {
      alertService.successSwel('Your answers have been verified.', 'Ok')
      remove()
      history.push('/client-portal');
    })
      .catch(error => {
        setSubmitting(false);
        if (error.response) {
          var rc = error.response.data.rc;
          var msg = error.response.data.msg;
          errorService.error(rc, msg)
        } else {
          alertService.warningSwel("Something went wrong. Please try again later.")
        }
      });
  }

  const QuestionsView = () => {
    return (
      <Formik initialValues={initialValues} validationSchema={validationSchemaQuestions} onSubmit={onSubmitAnswers}>
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <FieldArray name="answers">
              {() => (questions.map((answer, i) => {
                const answerErrors = (errors.answers?.length && errors.answers[i]) || {};
                const answerTouched = (touched.answers?.length && touched.answers[i]) || {};
                return (
                  <div key={i} className="list-group list-group-flush">
                    <div className="form-group text-left">
                      <label>{answer.text} </label>
                      <Field name={`answers.${i}.answer`} type="text" className={'form-control' + (answerErrors.answer && answerTouched.answer ? ' is-invalid' : '')} />
                      <ErrorMessage name={`answers.${i}.answer`} component="div" className="invalid-feedback" />
                    </div>
                  </div>
                );
              }))}
            </FieldArray>
            <button type="submit" disabled={isSubmitting} className="btn btn-primary mb-4">
              {isSubmitting ? 'Loading...' : 'Send'}
            </button>
          </Form>
        )}
      </Formik>
    );
  };

  const PasswordView = () => {
    return (
      <Formik initialValues={initialValues} validationSchema={validationSchemaPassword} onSubmit={onSubmitPassword}>
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div className="row d-flex align-items-center">
              <div className="form-group col-12 text-left">
                <label>New Password</label>
                <div className="input-group text-left">
                  <Field name="password" type={passwordShown ? "text" : "password"} className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} placeholder="●●●●●●●" />
                  <div className="input-group-append">
                    <span className="input-group-text p-0 pointer">
                      <i className={'feather px-3 py-2 ' + (passwordShown ? ' icon-eye-off' : 'icon-eye')} onClick={togglePasswordVisiblity} />
                    </span>
                  </div>
                  <ErrorMessage name="password" component="div" className="invalid-feedback" />
                </div>
              </div>
              <div className="form-group col-12 text-left">
                <label>Confirm password</label>
                <div className="input-group text-left">
                  <Field name="confirmPassword" type={passwordShownC ? "text" : "password"} className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} placeholder="●●●●●●●" />
                  <div className="input-group-append">
                    <span className="input-group-text p-0 pointer">
                      <i className={'feather px-3 py-2 ' + (passwordShownC ? ' icon-eye-off' : 'icon-eye')} onClick={togglePasswordVisiblityC} />
                    </span>
                  </div>
                  <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                </div>
              </div>
            </div>
            <button type="submit" disabled={isSubmitting} className="btn btn-primary mb-4">
              {isSubmitting ? 'Loading...' : 'Reset password'}
            </button>
          </Form>
        )}
      </Formik>
    );
  };

  useEffect(() => {
    const getQuestions = async () => {
      setError(null);
      setIsLoaded(true);
      try {
        const response = await userService.emailSecSettings(email)
        response.data.data.userSecurityQuestions.forEach((element) => {
          const obj = {
            key: element.question.questionId,
            text: element.question.question
          };
          const initVals = {
            questionId: element.question.questionId,
            answer: "",
          };
          initialValues.answers.push(initVals)
          setQuestions((prev) => [...prev, obj]);
        });
      } catch (error) {
        setError(error);
        console.log(error);
      }
      setIsLoaded(false);
    }
    getQuestions();
  }, [email, initialValues.answers]);

  if (isLoaded) {
    return (
      <div className="loader">
        <ReactLoading type={'bubbles'} color={'#112349'} height={100} width={100} className="d-flex mx-auto" />
        <h5>Loading...</h5>
      </div>
    )
  } else {
    if (error) {
      return (<ErrorView />)
    } else {
      return (
        <Aux>
          <Breadcrumb />
          <div className="auth-wrapper">
            <div className="auth-content">
              <div className="auth-bg">
                <span className="r" />
                <span className="r s" />
                <span className="r s" />
                <span className="r" />
              </div>
              <div className="card" data-aos="zoom-out">
                <div className="card-body text-center">
                  <div className="mb-4">
                    <i className="feather icon-unlock auth-icon" />
                  </div>
                  <h3 className="mb-4">New password</h3>
                  <p className="pt-2 pb-4">{sectionView ? 'Now you can change your password' : 'To recover your password you need to answer a security question'}</p>
                  {sectionView ? PasswordView() : QuestionsView()}
                </div>
              </div>
            </div>
          </div>
        </Aux>
      );
    }
  }
}