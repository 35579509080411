import Swal from "sweetalert2";

export const alertService = {
  successToast,
  infoToast,
  errorToast,
  warningToast,
  successSwel,
  infoSwel,
  warningSwel,
  errorSwel,
  successConfirmSwel,
  successConfirmOutsideSwel
};

export const AlertType = {
  Success: 'success',
  Error: 'error',
  Info: 'info',
  Warning: 'warning'
}

// Alert Toast
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

function successToast(message) {
  alertToast(AlertType.Success, message);
}

function infoToast(message) {
  alertToast(AlertType.Info, message)
}

function warningToast(message) {
  alertToast(AlertType.Warning, message);
}

function errorToast(message) {
  alertToast(AlertType.Error, message);
}

// Alert Swel
function successSwel(message) {
  const title = 'Successful process'
  alertSwel(AlertType.Success, title, message);
}

function infoSwel(message) {
  const title = 'Information'
  alertSwel(AlertType.Info, title, message);
}

function warningSwel(message) {
  const title = 'Warning'
  alertSwel(AlertType.Warning, title, message);
}

function errorSwel(message) {
  const title = 'Oops...'
  alertSwel(AlertType.Error, title, message);
}

// Alert Confirm Swel
function successConfirmSwel() {
  return Swal.fire({
    icon: AlertType.Success,
    title: 'Success',
    html: 'Check your inbox, we have sent you a validation code to your email. Use it to activate your account',
    timer: 5000,
    timerProgressBar: true,
    didOpen: () => {
      Swal.showLoading()
    },
  })
}

function successConfirmOutsideSwel() {
  return Swal.fire({
    icon: 'success',
    title: 'Success',
    html: 'Check your inbox, we have sent you a validation code to your email. Use it to activate your account',
    timer: 5000,
    timerProgressBar: true,
    didOpen: () => {
      Swal.showLoading()
    },
    allowOutsideClick: () => !Swal.isLoading()
  })
}

// core alert method
function alertToast(type, message) {
  Toast.fire({
    icon: type,
    title: message
  })
}

function alertSwel(type, title, message) {
  Swal.fire({
    icon: type,
    title: title,
    text: message,
    confirmButtonColor: '#112349',
    focusConfirm: false,
  })
}