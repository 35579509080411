import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import CoverPage from "../../components/CoverPage";
import { MainNavUser } from "../../components/header/MainNavUser";
import { FooterUser } from "../../components/footer/FooterUser";
import { ErrorView } from "../../pages/error/ErrorView";
import { toolService } from '../../services'
import imgCoverPage from '../../assets/images/figure/design-tools.svg';
import imgAcctAuditProgress from '../../assets/images/figure/best-place.svg';
import imgAcAuditSendEmail from '../../assets/images/figure/sending-emails.svg';
import imgAcAuditReport from '../../assets/images/figure/report-audit.svg';

import '../../assets/scss/style.scss';

export const AccountingAudit = () => {

  let title = "Accounting and audit";
  document.title = title + ' | SNHCPA';

  const [mainHeader, setMainHeader] = useState({
    layer: "",
    title: "",
    subtitle: "",
  });
  const [sectionOneSubOne, setSectionOneSubOne] = useState({
    paragraph: "",
  });
  const [sectionOneSubTwo, setSectionOneSubTwo] = useState({
    paragraph: "",
  });
  const [sectionTwo, setSectionTwo] = useState({
    title: "",
    subtitle: "",
    extraData: [{
      title: "",
    }],
  });
  const [sectionThree, setSectionThree] = useState({
    extraData: [{
      delay: "",
      title: "",
      paragraph: "",
    }],
  });

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const sectionMain = async () => {
      setError(null);
      setIsLoaded(true);
      try {
        const response = await toolService.getLayerSection('accounting-and-audit')
        const mainHeaderData = {
          layer: "ACCOUNTING-AND-AUDIT",
          title: response.data.data.mainHeader.mainHeaderTitle,
          subtitle: response.data.data.mainHeader.mainHeaderSubtitle,
        };
        setMainHeader(mainHeaderData);
        response.data.data.layerSections.forEach((section) => {
          const subsection = section.subSections[0];
          const subSection = {};
          if (subsection.subSectionTitle) {
            subSection["title"] = subsection.subSectionTitle;
          }
          if (subsection.subSectionSubtitle) {
            subSection["subtitle"] = subsection.subSectionSubtitle;
          }
          if (subsection.subSectionParagraph) {
            subSection["paragraph"] = subsection.subSectionParagraph;
          }
          subSection["extraData"] = [];
          subsection.subSectionExtraData.forEach((extradata) => {
            const extraData = {};
            if (extradata.extraDataDelay) {
              extraData["delay"] = extradata.extraDataDelay;
            }
            if (extradata.extraDataTitle) {
              extraData["title"] = extradata.extraDataTitle;
            }
            if (extradata.extraDataParagraph) {
              extraData["paragraph"] = extradata.extraDataParagraph;
            }
            subSection.extraData.push(extraData);
          });

          if (section.subSections[1]) {
            const subsectionExtra = section.subSections[1];
            const subSectionExtra = {};
            if (subsectionExtra.subSectionParagraph) {
              subSectionExtra["paragraph"] = subsectionExtra.subSectionParagraph;
            }
            setSectionOneSubTwo(subSectionExtra);
          }

          switch (section.sectionName) {
            case "SECTION_ONE":
              setSectionOneSubOne(subSection);
              break;
            case "SECTION_TWO":
              setSectionTwo(subSection);
              break;
            case "SECTION_THREE":
              setSectionThree(subSection);
              break;
            default:
              break;
          }
        });
      } catch (error) {
        setError(error);
        console.log(error);
      }
      setIsLoaded(false);
    }
    sectionMain();
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <ReactLoading type={'bubbles'} color={'#112349'} height={100} width={100} className="d-flex mx-auto" />
        <h5>Loading...</h5>
      </div>
    )
  } else {
    if (error) {
      return (<ErrorView />)
    } else {
      return (
        <div>
          <MainNavUser />
          <CoverPage data={mainHeader} img={imgCoverPage} />
          <main role='main' className='flex-shrink-0 content'>

            <section id="details" className="details section-bg">
              <div className="container">
                <div className="row content">
                  <div className="col-md-4 order-1 order-md-2" data-aos="fade-left">
                    <img src={imgAcctAuditProgress} className="img-fluid animated" alt="img-acct-audit-progress" />
                  </div>
                  <div className="col-md-8 pt-5 order-2 order-md-1" data-aos="fade-up">
                    <p className="paragraph">{sectionOneSubOne.paragraph}</p>
                  </div>
                </div>

                <div className="row content">
                  <div className="col-md-4" data-aos="fade-right">
                    <img src={imgAcAuditSendEmail} className="img-fluid animated" alt="img-acct-audit-sending-emails" />
                  </div>
                  <div className="col-md-8 pt-5" data-aos="fade-up">
                    <p className="paragraph">{sectionOneSubTwo.paragraph}</p>
                  </div>
                </div>

                <div className="row content">
                  <div className="col-lg-6 d-flex flex-column justify-contents-center" data-aos="fade-left">
                    <div className="content pt-4 pt-lg-0">
                      <h3>{sectionTwo.title}</h3>
                      <p className="font-italic">
                        {sectionTwo.paragraph}
                      </p>
                      <ul>
                        {sectionTwo.extraData.map((data, key) => {
                          return (
                            <li key={key}><i className="feather icon-check"></i>{data.title}</li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6" data-aos="zoom-in">
                    <img src={imgAcAuditReport} className="img-fluid animated" alt="imgacct-audit--report-audit" />
                  </div>
                </div>
              </div>
            </section>

            <section id="audits" className="audits section-bg-img">
              <div className="container">
                {sectionThree.extraData.map((data, key) => {
                  return (
                    <div key={key} className="row audits-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={data.delay}>
                      <div className="col-lg-5 my-auto">
                        <i className="feather icon-check"></i>
                        <h4>{data.title}</h4>
                      </div>
                      <div className="col-lg-7">
                        <p>{data.paragraph}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
          </main>
          <FooterUser />
        </div>
      );
    }
  }
}
