import axios from 'axios';

const get = (url) => {
  return axios.get(url);
}

const post = (url, data) => {
  return axios.post(url, data)
}

const put = (url, data) => {
  return axios.put(url, data);
}

// prefixed with underscored because delete is a reserved word in javascript
const _delete = (url, headers) => {
  return axios.delete(url, headers);
}

export const apiWrapper = {
  get,
  post,
  put,
  delete: _delete
}

