import * as yup from "yup"

const regularExpressions = {
  alphabeticalspace: /^['a-z ']{3,40}$/i,
  numeric: /^[0-9]+$/,
  emailValid: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  userpassword: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
}

const objectRules = {
  userType: yup
    .string()
    .required("Required field"),
  firstName: yup
    .string()
    .min(2, "The field must be at least 2 characters long")
    .max(64, "The field must be 64 characters or less ")
    .required('Required field'),
  lastName: yup
    .string()
    .min(2, "The field must be at least 2 characters long")
    .max(64, "The field must be 64 characters or less ")
    .required('Required field'),
  email: yup
    .string()
    .email("Email is invalid")
    .required("Required field")
    .matches(regularExpressions.emailValid, "Must be a valid email"),
  password: yup
    .string()
    .min(8, "The field must be at least 8 characters long")
    .max(15, "The field must be 15 characters or less ")
    .required("Required field")
    .matches(regularExpressions.userpassword, "One Uppercase, One Lowercase, One Number and One Special Case Character"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Required field'),
  oldPassword: yup
    .string()
    .min(8, "The field must be at least 8 characters long")
    .max(15, "The field must be 15 characters or less ")
    .required("Required field"),
  newPassword: yup
    .string()
    .min(8, "The field must be at least 8 characters long")
    .max(15, "The field must be 15 characters or less ")
    .required("Required field")
    .matches(regularExpressions.userpassword, "One Uppercase, One Lowercase, One Number and One Special Case Character"),
  confiPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Required field'),
  birthday: yup
    .string()
    .nullable('Birthday is required')
    .required('Required field'),
  corporationName: yup
    .string()
    .nullable("Required field")
    .required("Required field"),
  corporationId: yup
    .string()
    .nullable("Required field")
    .required("Required field"),
  company: yup
    .string()
    .nullable("Required field")
    .required("Required field"),
  gender: yup
    .string()
    .required('Required field'),
  address: yup
    .string()
    .min(10, "The field must be at least 10 characters long")
    .max(128, "The field must be 128 characters or less ")
    .required('Required field'),
  name: yup
    .string()
    .min(2, "The field must be at least 2 characters long")
    .max(64, "The field must be 64 characters or less ")
    .required('Required field'),
  subject: yup
    .string()
    .min(2, "The field must be at least 2 characters long")
    .max(64, "The field must be 64 characters or less ")
    .required('Required field'),
  message: yup
    .string()
    .min(2, "The field must be at least 2 characters long")
    .max(266, "The field must be 266 characters or less ")
    .required('Required field'),
  title: yup
    .string()
    .min(3, "The field must be at least 3 characters long")
    .max(48, "The field must be 48 characters or less ")
    .required('Required field'),
  subtitle: yup
    .string()
    .required('Required field')
    .min(6, "The field must be at least 6 characters long")
    .max(768, "The field must be 768 characters or less "),
  url: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!')
    .required('Required field'),
  paragraph: yup
    .string()
    .min(6, "The field must be at least 6 characters long")
    .max(1100, "The field must be 1100 characters or less ")
    .required('Required field'),
  subSectionParagraphOne: yup
    .string()
    .min(6, "The field must be at least 6 characters long")
    .max(768, "The field must be 768 characters or less ")
    .required('Required field'),
  subSectionParagraphTwo: yup
    .string()
    .min(6, "The field must be at least 6 characters long")
    .max(768, "The field must be 768 characters or less ")
    .required('Required field'),
  recaptcha: yup
    .string()
    .nullable("Verification expired. Check the checkbox again.")
    .required(),
  ceaLifecycle: yup
    .number()
    .moreThan(0, "It must be more than 1 minute")
    .lessThan(301, "It must be less than 300 minutes")
    .required("Time is required"),
  last4SS: yup
  .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(4, 'Must be exactly 4 digits')
    .max(4, 'Must be exactly 4 digits')
    .required("Required field"),
  phone: yup
    .string()
    .min(8, "The field must be at least 8 characters long")
    .required('Required field'),
  publicKey: yup
    .string()
    .required('Required field'),
  privateKey: yup
    .string()
    .required('Required field'),
  recaptchaKey: yup
    .string()
    .required('Required field'),
  question: yup
    .string()
    .required('Required field'),
  folderName: yup
    .string()
    .min(1, "The field must be at least 1 characters long")
    .max(32, "The field must be 32 characters or less ")
    .required('Required field'),
  receivedFolderId: yup
    .string()
    .required('Required field'),
  securityQuestions: yup.array().of(
    yup.object().shape({
      questionId: yup
        .number()
        .required("Required field"),
      answer: yup
        .string()
        .required('Required field')
    })
  ),
  answers: yup.array().of(
    yup.object().shape({
      questionId: yup
        .number(),
      answer: yup
        .string()
        .required('Required field')
    })
  ),

  extraDataSubTitleParag: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .min(3, "The field must be at least 3 characters long")
        .max(48, "The field must be 48 characters or less ")
        .required("Required field"),
      paragraph: yup
        .string()
        .min(6, "The field must be at least 6 characters long")
        .max(768, "The field must be 768 characters or less ")
        .required('Required field'),
      reference: yup
        .string()
    })
  ),
  extraDataSubTitle: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .min(3, "The field must be at least 3 characters long")
        .max(85, "The field must be 85 characters or less ")
        .required("Required field"),
    })
  ),
  linkDetails: yup.array().required().of(
    yup.object().shape({
      id: yup
        .number(),
      title: yup
        .string()
        .min(3, "The field must be at least 3 characters long")
        .max(48, "The field must be 48 characters or less ")
        .required('Required field'),
      url: yup
        .string()
        .required('Required field')
    })
  ),

  timePass: yup.array().of(
    yup.object().shape({
      ceaLifecycle: yup
        .number()
        .moreThan(0, "It must be more than 1 minute")
        .lessThan(301, "It must be less than 300 minutes")
        .required("Time is required"),
    })
  ),
  changeSecurityQuestions: yup.array().of(
    yup.object().shape({
      question: yup.object().shape({
        questionId: yup
          .string()
          .required("Required field"),
      }),
      answer: yup
        .string()
        .required("Required field"),
    })
  ),
}

export const getSchema = (params = []) => {
  const subset = Object.fromEntries(
    Object.entries(objectRules).filter(([key]) => params.includes(key))
  );
  return yup.object().shape(subset);
};
