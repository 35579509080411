import React from 'react';
export const Error404View = ({ history }) => {
  function goBack() {
    history.goBack();
  }
  return (
    <>
      <div className="diagonal-box bg-two">
        <div className="content-error">
          <span className="error-title">
            Error
          </span> <br />
          <span className="error-number">
            404
          </span><br />
          <span className="error-description h6">
            Oops! You're lost<br /><br />
            Sorry, The page you were looking for doesn't exist
          </span><br />
          <button className="btn btn-secondary text-uppercase mt-4 font-weight-bold" onClick={goBack}>Go Back</button>
        </div>
      </div>
    </>
  )
}
