import React from 'react';
import ReactLoading from 'react-loading';
import Card from "../../components/MainCard";

export const Loading = () => {
  return (
    <Card>
      <div className="loader" style={{ height: '55vh' }}>
        <ReactLoading type={'bubbles'} color={'#112349'} height={100} width={100} className="d-flex mx-auto" />
        <h5>Loading...</h5>
      </div>
    </Card>
  )
}
