import Crypto from "crypto-js";

export const UseCrypto = (chain, action = 'en') => {
  const key = Crypto.enc.Hex.parse(process.env.REACT_APP_CYPHER_KEY);
  const iv = Crypto.enc.Hex.parse(process.env.REACT_APP_CYPHER_IV);

  if (action === 'en') {
    return Crypto.AES.encrypt(chain, key, { iv }).toString();
  } else {
    return Crypto.AES.decrypt(chain, key, { iv }).toString(Crypto.enc.Utf8);
  };
}

export const UseCryptoObject = (chain, action = 'en') => {
  const key = Crypto.enc.Hex.parse(process.env.REACT_APP_CYPHER_KEY);
  const iv = Crypto.enc.Hex.parse(process.env.REACT_APP_CYPHER_IV);
  
  if (action === 'en') {
    return Crypto.AES.encrypt(JSON.stringify(chain), key, { iv }).toString();
  } else {
    return JSON.parse((Crypto.AES.decrypt(chain, key, { iv })).toString(Crypto.enc.Utf8));
  }
}