import { apiWrapper } from '../helpers';
import { alertService } from '../services';
const baseUrl = `${process.env.REACT_APP_ADMIN_API}:8000/snhcpa/v1/folders`;

//Download File 
const downloadFile = (values) => {
  try {
  const id = values.folderFileId
  const url = `${baseUrl}/files/` + id
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', values.folderFileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  alertService.successSwel('Successful process', 'Ok')
  } catch (error) {
    alertService.warningSwel("Something went wrong. Please try again later.")
  }
}

//Get Folder Users 
const foldersUser = (id, data, { setAllFolders }) => {
  try {
    apiWrapper.get(`${baseUrl}/users/` + id)
      .then(response => {
        setAllFolders([]);
        response.data.data.forEach((element) => {
          if (data.actualFolderId !== element.userFolderId) {
            const obj = {
              idFolder: element.userFolderId,
              value: element.userFolderId,
              name: element.userFolderName
            };
            setAllFolders((prev) => [...prev, obj]);
          }
        });
      });
  } catch (error) {
    alertService.warningSwel("Something went wrong. Please try again later.")
  }
}

// Add Folder
const addFolder = (data) => {
  return apiWrapper.post(`${baseUrl}`, data)
}

// Update Folder
const updFolder = (data) => {
  return apiWrapper.put(`${baseUrl}`, data)
}


//Move File
const move = (data) => {
  return apiWrapper.put(`${baseUrl}/files`, data)
}

// Delete File
const delFile = (id) => {
  return apiWrapper.delete(`${baseUrl}/files/` + id)
}

// Delete Folder
const delFolder = (id) => {
  return apiWrapper.delete(`${baseUrl}/` + id)
}

export const folderService = {
  downloadFile,
  foldersUser,
  addFolder,
  updFolder,
  move,
  delFile,
  delFolder,
};




