import { alertService } from "../services/AlertSwal";
import { removeKeyInStorage, clearStorage } from '../utils/Common';

export const errorService = {
  error,
};

function removeUserData (){
  removeKeyInStorage('tokenUserValid');
  removeKeyInStorage('objectInfo');
  removeKeyInStorage('userValid');
  clearStorage();
  window.location.href = '/';
}

function error(rc, msg) {
  switch (rc) {
    case "-999":
    case "-1":
    case "-3":
    case "-6":
    case "-9":
    case "-10":
    case "-11":
    case "-12":
    case "-13":
    case "-14":
    case "-15":
    case "-17":
    case "-18":
    case "-19":
    case "-20":
    case "-21":
    case "-22":
      return alertService.errorToast(msg, 'Ok')
    case "-4":
    case "-5":
    case "-8":
    case "-25":
    case "-27":
      return alertService.errorSwel(msg, 'Ok')
    case "-7":
      return alertService.warningSwel(msg, 'Ok')
    case "-16":
      return removeUserData()
    default:
      return alertService.warningSwel("Something went wrong. Please try again later.")
  }
}