import { Home } from '../pages/user/Home';
import { AccountingAudit } from '../pages/user/AccountingAudit';
import { Tax } from '../pages/user/Tax';
import { Outsourcing } from '../pages/user/Outsourcing';
import { CloudAccounting } from '../pages/user/CloudAccounting';
import { AboutUs } from '../pages/user/AboutUs';
import { Contact } from '../pages/user/Contact';
import { ResourcesLinks } from '../pages/user/ResourcesLinks';
import { Login } from '../pages/authentication/Login';
import { SignUp } from '../pages/authentication/SignUp';
import { ForgotPassword } from '../pages/authentication/ForgotPassword';
import { OneTimePassword } from '../pages/authentication/OneTimePassword';
import { ResetPassword } from '../pages/authentication/ResetPassword';
import { Dashboard } from '../pages/user/dashboard/Dashboard';
import { MyDocuments } from '../pages/user/dashboard/MyDocuments';
import { Profile } from '../pages/user/dashboard/Profile';
import { SecuritySettings } from '../pages/user/dashboard/SecuritySettings';

const roles = {
  admin: 'ADMIN',
  subAdmin: 'SUBADMIN',
  user: 'USER'
}

const route = [
  // LANDING PAGE
  { path: '/', exact: true, name: 'Home', component: Home, type: 'public', logged: false, roles: [] },
  { path: '/services/accounting-and-audit', exact: true, name: 'AccountingAudit', component: AccountingAudit, type: 'public', logged: false, roles: [] },
  { path: '/services/tax', exact: true, name: 'Tax', component: Tax, type: 'public', logged: false, roles: [] },
  { path: '/services/outsourcing', exact: true, name: 'Outsourcing', component: Outsourcing, type: 'public', logged: false, roles: [] },
  { path: '/services/cloud-accounting-services', exact: true, name: 'CloudAccounting', component: CloudAccounting, type: 'public', logged: false, roles: [] },
  { path: '/about-us', exact: true, name: 'AboutUs', component: AboutUs, type: 'public', logged: false, roles: [] },
  { path: '/contact-us', exact: true, name: 'Contact us', component: Contact, type: 'public', logged: false, roles: [] },
  { path: '/resources-links', exact: true, name: 'ResourcesLinks', component: ResourcesLinks, type: 'public', logged: false, roles: [] },
  // AUTH
  { path: '/client-portal', exact: true, name: 'Login', component: Login, type: 'public', logged: false, roles: [] },
  { path: '/sign-up', exact: true, name: 'SignUp', component: SignUp, type: 'public', logged: false, roles: [] },
  { path: '/forgot-password', exact: true, name: 'ForgotPassword', component: ForgotPassword, type: 'public', logged: false, roles: [] },
  { path: '/otp', exact: true, name: 'OneTimePassword', component: OneTimePassword, type: 'private', logged: false, roles: [] },
  { path: '/reset-password', exact: true, name: 'ResetPassword', component: ResetPassword, type: 'private', logged: false, roles: [] },
  // USER
  { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard, type: 'private', logged: true, roles: [roles.user] },
  { path: '/my-documents', exact: true, name: 'MyDocuments', component: MyDocuments, type: 'private', logged: true, roles: [roles.user] },
  { path: '/profile', exact: true, name: 'Profile', component: Profile, type: 'private', logged: true, roles: [roles.user] },
  { path: '/security-settings', exact: true, name: 'SecuritySettings', component: SecuritySettings, type: 'private', logged: true, roles: [roles.user] },
];

export default route;


