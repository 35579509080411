import React from 'react';
import { Formik, Field, FieldArray, ErrorMessage } from 'formik';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Card from "../../components/MainCard";
import { getSchema } from "../../config/Validations";
import { userService, errorService, alertService } from '../../services'

export const FormSecQues = ({ questions, questionUser, setQuestionUser, userId }) => {


  const validationSchemaQnsAns = getSchema(["changeSecurityQuestions"]);

  function handleChange(evt, index) {
    const value = evt.target.value;
    const securityCopy = [...questionUser.changeSecurityQuestions];
    securityCopy[index].question.questionId = value;
    setQuestionUser()
    setQuestionUser(prevState => ({
      changeSecurityQuestions: securityCopy
    }))
  }

  function onSubmitQnsAns(values, { setSubmitting }) {
    const transform = values.changeSecurityQuestions.map(element => ({
      newQuestionId: element.question.questionId,
      oldQuestionId: element.question.oldQuestionId,
      answer: element.answer
    }));

    const data = {
      userId: userId,
      securityQuestions: transform
    }

    // CALL TO API
    userService.userQuestion(data).then(response => {
      alertService.successSwel('Data successfully updated', 'Ok')
      setSubmitting(false);
      data.securityQuestions.forEach((element) => {
        const changeSecurityQuestions = [];
        const data = {
          question: {
            questionId: element.newQuestionId,
            oldQuestionId: element.newQuestionId,
            question: ''
          },
          answer: element.answer
        }
        changeSecurityQuestions.push(data);
        setQuestionUser((prevState) => ({
          ...prevState,
          changeSecurityQuestions: changeSecurityQuestions
        }));
      });

    }).catch(error => {
      setSubmitting(false);
      if (error.response) {
        var rc = error.response.data.rc;
        var msg = error.response.data.msg;
        errorService.error(rc, msg)
      } else {
        alertService.warningSwel("Something went wrong. Please try again later.")
      }
    });
  }

  return (
    <Card title='Change security question'>
      <Formik initialValues={questionUser} validationSchema={validationSchemaQnsAns} onSubmit={onSubmitQnsAns} enableReinitialize>
        {({ handleSubmit, touched, errors, isSubmitting }) => (
          <Form noValidate onSubmit={handleSubmit} >
            <Row className="flex-column align-content-center">
              <Col md={12}>
                <FieldArray name="changeSecurityQuestions">
                  {() => (questionUser.changeSecurityQuestions.map((element, i) => {
                    const qnsAnsErrors = (errors.changeSecurityQuestions?.length && errors.changeSecurityQuestions[i]) || {};
                    const qnsAnsTouched = (touched.changeSecurityQuestions?.length && touched.changeSecurityQuestions[i]) || {};

                    return (
                      <Row key={i} className="form-group text-left justify-content-center">
                        <Col md={6}>
                          <label >Question {i + 1} </label>
                          <Field as="select" name={`changeSecurityQuestions.${i}.question.questionId`} className={'form-control' + (qnsAnsErrors.question && qnsAnsTouched.question ? ' is-invalid' : '')} value={element.question.questionId} onChange={(e) => { handleChange(e, i) }} disabled={isSubmitting}>
                            <option value="">Select question </option>
                            {questions.map((value, index) => {
                              return (
                                <option key={index} value={value.key}>
                                  {value.text}
                                </option>
                              )
                            })}
                          </Field>
                          <ErrorMessage name={`changeSecurityQuestions.${i}.question.questionId`} component="div" className="invalid-feedback" />
                        </Col>
                        <Col md={6}>
                          <label>Answer {i + 1}</label>
                          <Field name={`changeSecurityQuestions.${i}.answer`} type="text" className={'form-control' + (qnsAnsErrors.answer && qnsAnsTouched.answer ? ' is-invalid' : '')} />
                          <ErrorMessage name={`changeSecurityQuestions.${i}.answer`} component="div" className="invalid-feedback" />
                        </Col>
                      </Row>
                    );
                  }))}
                </FieldArray>
              </Col>
            </Row>
            <Form.Row className="mt-4">
              <Col className="text-center">
                <Button type="submit" disabled={isSubmitting} className="btn btn-primary m-0">
                  {isSubmitting ? 'Loading...' : 'Save'}
                </Button>
              </Col>
            </Form.Row>
          </Form>
        )}
      </Formik>
    </Card>
  );
}