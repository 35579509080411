
import React from 'react';
import { Table } from 'react-bootstrap';
import { folderService } from '../../services'
import imgFilePdf from '../../assets/images/figure/file-pdf.svg';
import imgFileDoc from '../../assets/images/figure/file-xls.svg';
import imgFileXls from '../../assets/images/figure/file-doc.svg';

export const TableFiles = (props) => {

  const { data, setData, idUser, setUserFolder, setAllFolders, handleShowMove, handleShowDeleteFile } = props

  const selectItem = (event) => {
    if (event.userFolderId) {
      setData((prevState) => ({
        ...prevState,
        typeModal: 'Update',
        form: {
          id: event.userFolderId,
          folderName: event.userFolderName,
        }
      }));
    } else {
      setData((prevState) => ({
        ...prevState,
        form: {
          id: event.folderFileId,
          fileName: event.folderFileName
        }
      }));
    }

  };
  const moveFile = (event) => {
    setUserFolder((prevState) => ({
      ...prevState,
      filesId: [event.folderFileId],
    }));
  };
  function download(values) {
    folderService.downloadFile(values)
  }
  const getFolders = () => {
    folderService.foldersUser(idUser, data, {setAllFolders})
  };

  return (
    <Table responsive bordered>
      <thead className="border-0">
        <tr>
          <th>Name</th>
          <th>Upload by</th>
          <th>Upload date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.filesFolder.userFolderFiles?.map((element, key) => {
          return (
            <tr key={key + 1}>
              <td>
                <div className="d-flex align-items-center">
                  {/* <div className="custom-control custom-control-sm custom-checkbox notext mr-2">
                   <input type="checkbox" className="custom-control-input" id={'file-check-n' + key + 1} /><label className="custom-control-label" htmlFor={'file-check-n' + key + 1}></label> 
                  </div>*/}
                  <div className="file-icon">
                    {(() => {
                      switch (element.folderFileExtension) {
                        case 'pdf':
                          return (
                            <img src={imgFilePdf} className="file-icon-type" alt="img-file-pdf" />
                          )
                        case 'xls':
                        case 'xlsx':
                          return (
                            <img src={imgFileXls} className="file-icon-type" alt="img-file-xls" />
                          )
                        default:
                          return (
                            <img src={imgFileDoc} className="file-icon-type" alt="img-file-doc" />
                          )
                      }
                    })()}
                  </div>
                  <div className="file-name text-truncate">
                    <div className="file-name-text text-truncate"><button className="btn btn-link m-0 pl-0 text-decoration-none title" onClick={() => { download(element) }}>{element.folderFileName} </button>
                    </div>
                  </div>
                </div>
              </td>
              <td className="text-center">{element.folderFileUploadBy} </td>
              <td className="text-center">{element.folderFileCreated} </td>
              <td className="text-center w-25">
                <button type="button" className="btn btn-secondary btn-sm p-1 mx-1 px-2" onClick={() => { moveFile(element); handleShowMove(); getFolders() }}>
                  <i className="feather icon-move mr-0"></i>
                </button>
                <button type="button" className="btn btn-outline-primary shadow-none btn-sm p-1 mx-1 px-2" onClick={() => { download(element) }}>
                  <i className="feather icon-download mr-0"></i>
                </button>
                {data.userRole !== 'USER' ? (
                  <button type="button" className="btn btn-outline-danger btn-sm p-1 mx-1 px-2" onClick={() => { selectItem(element); handleShowDeleteFile() }}>
                    <i className="feather icon-trash-2 mr-0"></i>
                  </button>
                ) : ''}
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}