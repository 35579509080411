import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import CoverPage from "../../components/CoverPage";
import { MainNavUser } from "../../components/header/MainNavUser";
import { FooterUser } from "../../components/footer/FooterUser";
import { ErrorView } from "../../pages/error/ErrorView";
import { toolService } from '../../services'
import imgCoverPage from '../../assets/images/figure/solution-mindset-35bi.svg';
import imgLinkInfoFlow from '../../assets/images/figure/information-flow-Isometric.svg';
import '../../assets/scss/style.scss';

export const ResourcesLinks = () => {

  let title = "Resources & Links";
  document.title = title + ' | SNHCPA';

  const [mainHeader, setMainHeader] = useState({
    layer: "",
    title: "",
    subtitle: "",
  });
  const [sectionOne, setSectionOne] = useState({
    title: "",
    paragraph: "",
    extraData: [{
      name: "",
      link: "",
    }],
  });

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const sectionMain = async () => {
      setError(null);
      setIsLoaded(true);
      try {
        const response = await toolService.getLayerSection('resources-and-links')
        const mainHeaderData = {
          layer: "RESOURCES-AND-LINKS",
          title: response.data.data.mainHeader.mainHeaderTitle,
          subtitle: response.data.data.mainHeader.mainHeaderSubtitle,
        };
        setMainHeader(mainHeaderData);
        response.data.data.layerSections.forEach((section) => {
          const subsection = section.subSections[0];
          const subSection = {};
          if (subsection.subSectionTitle) {
            subSection["title"] = subsection.subSectionTitle;
          }
          if (subsection.subSectionParagraph) {
            subSection["paragraph"] = subsection.subSectionParagraph;
          }
          subSection["extraData"] = [];
          subsection.subSectionExtraData.forEach((extradata) => {
            const extraData = {};
            if (extradata.extraDataExternalLink) {
              extraData["link"] = extradata.extraDataExternalLink;
            }
            if (extradata.extraDataTitle) {
              extraData["name"] = extradata.extraDataTitle;
            }
            subSection.extraData.push(extraData);
          });

          switch (section.sectionName) {
            case "SECTION_ONE":
              setSectionOne(subSection);
              break;
            default:
              break;
          }
        });
      } catch (error) {
        setError(error);
        console.log(error);
      }
      setIsLoaded(false);
    }
    sectionMain();
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <ReactLoading type={'bubbles'} color={'#112349'} height={100} width={100} className="d-flex mx-auto" />
        <h5>Loading...</h5>
      </div>
    )
  } else {
    if (error) {
      return (<ErrorView />)
    } else {
      return (
        <div>
          <MainNavUser />
          <CoverPage data={mainHeader} img={imgCoverPage} />

          <main role='main' className='flex-shrink-0 content'>
            <section id="details" className="details section-bg">
              <div className="container">
                <div className="row content">
                  <div className="col-md-4" data-aos="fade-right">
                    <img src={imgLinkInfoFlow} className="img-fluid animated" alt="img-link-info-flow" />
                  </div>
                  <div className="col-md-8 pt-4" data-aos="fade-up">
                    <h3 className="text-uppercase" >{sectionOne.title} </h3>
                    <p className="font-italic paragraph">{sectionOne.paragraph}</p>
                    <ul>
                      {sectionOne.extraData.map((data, key) => {
                        return (
                          <li key={key}>
                            <i className="feather icon-check" />
                            <Link
                              to={{ pathname: data.link }}
                              target="_blank"
                              className="text-primary text-uppercase"
                            >
                              {data.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </main>
          <FooterUser />
        </div>
      );
    }
  }
}