import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import CoverPage from "../../components/CoverPage";
import { MainNavUser } from "../../components/header/MainNavUser";
import { FooterUser } from "../../components/footer/FooterUser";
import { ErrorView } from "../../pages/error/ErrorView";
import { toolService } from '../../services'
import imgCoverPage from '../../assets/images/figure/finance-re-gnv2.svg';
import imgOutDataTrends from '../../assets/images/figure/data-trends.svg';
import imgOutTeamSpirit from '../../assets/images/figure/progress.svg';
import imgOutOffice from '../../assets/images/figure/in-the-office.svg';
import '../../assets/scss/style.scss';

export const Outsourcing = () => {

  let title = "Outsourcing - Our Services";
  document.title = title + ' | SNHCPA';

  const [mainHeader, setMainHeader] = useState({
    layer: "",
    title: "",
    subtitle: "",
  });
  const [sectionOneSubOne, setSectionOneSubOne] = useState({
    title: "",
    paragraph: "",
  });
  const [sectionOneSubTwo, setSectionOneSubTwo] = useState({
    title: "",
    paragraph: "",
  });
  const [sectionOneSubThree, setSectionOneSubThree] = useState({
    title: "",
    paragraph: "",
  });

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const sectionMain = async () => {
      setError(null);
      setIsLoaded(true);
      try {
        const response = await toolService.getLayerSection('outsourcing')
        const mainHeaderData = {
          layer: "OUTSOURCING",
          title: response.data.data.mainHeader.mainHeaderTitle,
          subtitle: response.data.data.mainHeader.mainHeaderSubtitle,
        };
        setMainHeader(mainHeaderData);
        response.data.data.layerSections.forEach((section) => {
          const subsection = section.subSections[0];
          const subSection = {};
          if (subsection.subSectionTitle) {
            subSection["title"] = subsection.subSectionTitle;
          }
          if (subsection.subSectionParagraph) {
            subSection["paragraph"] = subsection.subSectionParagraph;
          }

          if (section.subSections[1]) {
            const subsectionExtraTwo = section.subSections[1];
            const subSectionExtraTwo = {};
            if (subsectionExtraTwo.subSectionTitle) {
              subSectionExtraTwo["title"] = subsectionExtraTwo.subSectionTitle;
            }
            if (subsectionExtraTwo.subSectionParagraph) {
              subSectionExtraTwo["paragraph"] = subsectionExtraTwo.subSectionParagraph;
            }
            setSectionOneSubTwo(subSectionExtraTwo);
          }

          if (section.subSections[2]) {
            const subsectionExtraThree = section.subSections[2];
            const subSectionExtraTwo = {};
            if (subsectionExtraThree.subSectionTitle) {
              subSectionExtraTwo["title"] = subsectionExtraThree.subSectionTitle;
            }
            if (subsectionExtraThree.subSectionParagraph) {
              subSectionExtraTwo["paragraph"] = subsectionExtraThree.subSectionParagraph;
            }
            setSectionOneSubThree(subSectionExtraTwo);
          }

          switch (section.sectionName) {
            case "SECTION_ONE":
              setSectionOneSubOne(subSection);
              break;
            default:
              break;
          }
        });
      } catch (error) {
        setError(error);
        console.log(error);
      }
      setIsLoaded(false);
    }
    sectionMain();
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <ReactLoading type={'bubbles'} color={'#112349'} height={100} width={100} className="d-flex mx-auto" />
        <h5>Loading...</h5>
      </div>
    )
  } else {
    if (error) {
      return (<ErrorView />)
    } else {
      return (
        <div>
          <MainNavUser />
          <CoverPage data={mainHeader} img={imgCoverPage} />
          <main role='main' className='flex-shrink-0 content'>
            <section id="details" className="details section-bg">
              <div className="container">
                <div className="row content">
                  <div className="col-md-4 order-1 order-md-2" data-aos="fade-left">
                    <img src={imgOutDataTrends} className="img-fluid animated" alt="img-data-trends" />
                  </div>
                  <div className="col-md-8 pt-5 order-2 order-md-1" data-aos="fade-up">
                    <h3>{sectionOneSubOne.title}</h3>
                    <p className="paragraph">{sectionOneSubOne.paragraph} </p>
                  </div>
                </div>

                <div className="row content">
                  <div className="col-md-4" data-aos="fade-right">
                    <img src={imgOutTeamSpirit} className="img-fluid animated" alt="img-team-spirit" />
                  </div>
                  <div className="col-md-8 pt-5" data-aos="fade-up">
                    <h3>{sectionOneSubTwo.title}</h3>
                    <p className="paragraph">{sectionOneSubTwo.paragraph} </p>
                  </div>
                </div>

                <div className="row content">
                  <div className="col-md-8 pt-5" data-aos="fade-up">
                    <h3>{sectionOneSubThree.title}</h3>
                    <p className="paragraph">{sectionOneSubThree.paragraph} </p>
                  </div>
                  <div className="col-md-4" data-aos="fade-right">
                    <img src={imgOutOffice} className="img-fluid animated" alt="img-office" />
                  </div>
                </div>
              </div>
            </section>
          </main>
          <FooterUser />
        </div>
      );
    }
  }
}
