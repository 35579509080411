import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { errorService, alertService, folderService } from '../../services'

export const DeleteFolder = ({ showDeleteFolder, setShowDeleteFolder, handleCloseDeleteFolder, form, setData }) => {

  const [state, setState] = useState({
    loading: false,
  });

  function onSubmitDelete() {
    const folderId = form.id
    setState({ loading: true });
    folderService.delFolder(folderId).then(response => {
      setData((prevState) => ({
        ...prevState,
        folders: []
      }));
      response.data.data.forEach((userData) => {
        const name = userData.userFolderName
        if (name !== 'Default folder') {
          setData((prevState) => ({
            ...prevState,
            folders: [
              ...prevState.folders,
              userData
            ]
          }))
        } else {
          setData((prevState) => ({
            ...prevState,
            files: userData
          }));
        }
      })
      setShowDeleteFolder(false)
      alertService.successSwel('Data successfully updated', 'Ok')
      setState({ loading: false });
    }).catch(error => {
      setState({ loading: false });
      if (error.response) {
        var rc = error.response.data.rc;
        var msg = error.response.data.msg;
        if (rc === '-24') {
          setShowDeleteFolder(false)
          alertService.errorSwel(msg, 'Ok')
        } else {
          errorService.error(rc, msg)
        }
      } else {
        alertService.warningSwel("Something went wrong. Please try again later.")
      }
    });
  }

  return (
    <>
      <Modal show={showDeleteFolder} onHide={handleCloseDeleteFolder}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Are you sure you want to delete the <b>"{form && form.folderName}"</b>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteFolder}>
            No
          </Button>
          <Button type="button" variant="danger" disabled={state.loading} onClick={onSubmitDelete}>
            {state.loading ? 'Loading...' : 'Yes'}
          </Button>
        </Modal.Footer>
      </Modal>
    </ >
  );
}

