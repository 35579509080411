import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import { Button, Modal, Row } from 'react-bootstrap';
import { getKeyInStorage } from '../../utils/Common';
import { notificationService, errorService, alertService } from '../../services'
import imgFilePdf from '../../assets/images/figure/file-pdf.svg';
import imgFileDoc from '../../assets/images/figure/file-xls.svg';
import imgFileXls from '../../assets/images/figure/file-doc.svg';
import imgFileDefault from '../../assets/images/figure/file-default.svg';

export const DropZone = ({ showUp, handleCloseUp, data, setData }) => {

  const fileInputRef = useRef();
  const progressRef = useRef();
  const uploadRef = useRef();
  const uploadModalRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [validFiles, setValidFiles] = useState([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const token = getKeyInStorage('tokenUserValid');
  const idUser = data.userId
  const folderId = data.actualFolderId
  const headers = {
    'x-session': token
  }
  useEffect(() => {
    let filteredArr = selectedFiles.reduce((acc, current) => {
      const x = acc.find(item => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    setValidFiles([...filteredArr]);

  }, [selectedFiles]);

  const preventDefault = (e) => {
    e.preventDefault();
    // e.stopPropagation();
  }

  const dragOver = (e) => {
    preventDefault(e);
  }

  const dragEnter = (e) => {
    preventDefault(e);
  }

  const dragLeave = (e) => {
    preventDefault(e);
  }

  const fileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  }

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  }

  const fileInputClicked = () => {
    fileInputRef.current.click();
  }

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles(prevArray => [...prevArray, files[i]]);
      } else {
        files[i]['invalid'] = true;
        setSelectedFiles(prevArray => [...prevArray, files[i]]);
        setErrorMessage('File type not permitted');
        setUnsupportedFiles(prevArray => [...prevArray, files[i]]);
      }
    }
  }

  const validateFile = (file) => {
    const type = fileType(file.name)
    const validTypes = [
      'application/pdf',
      'application/docx',
      'application/xlsx',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'image/png',
      'image/jpeg'

    ];
    if (validTypes.indexOf(file.type) === -1 && type !== 'doc' && type !== 'docx') {
      return false;
    }
    return true;
  }

  const fileSize = (size) => {
    if (size === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  const fileType = (fileName) => {
    return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
  }
  const removeFile = (name) => {
    const index = validFiles.findIndex(e => e.name === name);
    const index2 = selectedFiles.findIndex(e => e.name === name);
    const index3 = unsupportedFiles.findIndex(e => e.name === name);
    validFiles.splice(index, 1);
    selectedFiles.splice(index2, 1);
    setValidFiles([...validFiles]);
    setSelectedFiles([...selectedFiles]);
    if (index3 !== -1) {
      unsupportedFiles.splice(index3, 1);
      setUnsupportedFiles([...unsupportedFiles]);
    }
  }
  const uploadFiles = async () => {
    uploadModalRef.current.style.display = 'block';
    uploadRef.current.innerHTML = 'File(s) Uploading...';
    for (let i = 0; i < validFiles.length; i++) {

      const formData = new FormData();
      formData.append('file', validFiles[i]);
      axios.post(process.env.REACT_APP_ADMIN_API + ":8000/snhcpa/v1/folders/" + folderId + "/files/upload", formData, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const uploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
          progressRef.current.innerHTML = `${uploadPercentage}%`;
          progressRef.current.style.width = `${uploadPercentage}%`;

          if (uploadPercentage === 100) {
            uploadRef.current.innerHTML = 'File(s) Uploaded';
            closeUploadModal()
            handleCloseUp()
            alertService.successSwel('Data successfully updated', 'Ok')
          }
        },
      }
      ).then(response => {
        pushNotification(validFiles)
        validFiles.length = 0;
        setValidFiles([...validFiles]);
        setSelectedFiles([...validFiles]);
        setUnsupportedFiles([...validFiles]);
        if (Object.keys(data.filesFolder).length === 0) {
          response.data.data.forEach((userData) => {
            const name = userData.userFolderName
            if (name === 'Default folder') {
              setData((prevState) => ({
                ...prevState,
                files: {
                  ...prevState.files,
                  userFolderFiles: userData.userFolderFiles
                },
              }))
            }
          })
        } else {
          setData((prevState) => ({
            ...prevState,
            filesFolder: {
              ...prevState.filesFolder,
              userFolderFiles: response.data.data
            },
          }))
          data.folders.forEach((userData, index) => {
            if (userData.userFolderId === folderId) {
              let folders = [...data.folders];
              folders[index] = { ...folders[index], userFolderFiles: response.data.data };
              setData((prevState) => ({
                ...prevState,
                folders: folders
              }))
            }
          })
        }
      })
        .catch((error) => {
          uploadRef.current.innerHTML = `<span className="text-error">Error Uploading File(s)</span>`;
          progressRef.current.style.backgroundColor = 'red';
          if (error.response) {
            var rc = error.response.data.rc;
            var msg = error.response.data.msg;
            errorService.error(rc, msg)
          } else {
            alertService.warningSwel("Something went wrong. Please try again later.")
          }
        })
    }
  }

  const pushNotification = async (files) => {
    const dataFile = files
    dataFile.forEach((element) => {
      const files = {
        userId: idUser,
        notificationType: 'UPLOAD_FILE',
        tokensToReplaces: [{
          tokenToReplaceName: '$FILE$',
          tokenToReplaceValue: element.name
        }]
      }
      // CALL TO API
      notificationService.sendNotifications(files)
    })
  }

  const closeUploadModal = () => {
    uploadModalRef.current.style.display = 'none';
  }

  return (
    <>
      <Modal show={showUp} onHide={handleCloseUp}>
        <Modal.Header closeButton onClick={() => {
          setSelectedFiles([]);
        }}>
          <Modal.Title> Upload file  {unsupportedFiles.length ? (<small className="text-danger h6">(Please remove all unsupported files.)</small>) : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          maxHeight: 'calc(100vh - 210px)',
          overflowY: 'auto'
        }}>
          <Row>
            <div className="col-12">
              <div className="drop-container"
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                onClick={fileInputClicked}
              >
                <div className="drop-message">
                  <div className="upload-icon h4 text-primary mb-3"><i className="feather icon-upload"></i></div>
                  <span className="drop-message-text h6"><span>Drag and drop</span> file here or <span>browse</span></span>
                </div>
                <input
                  ref={fileInputRef}
                  className="file-input"
                  type="file"
                  multiple
                  onChange={filesSelected}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="file-display-container">
                {
                  validFiles.map((data, i) =>
                    <div className="file-status-bar" key={i}>
                      <div className="file-upload-item"><div className="file-upload-icon">
                        {(() => {
                          switch (fileType(data.name)) {
                            case 'pdf':
                              return (
                                <img src={imgFilePdf} className="file-icon-type" alt="img-creative-solutions" />
                              )
                            case 'xls':
                            case 'xlsx':
                              return (
                                <img src={imgFileXls} className="file-icon-type" alt="img-creative-solutions" />
                              )
                            case 'docx':
                            case 'doc':
                              return (
                                <img src={imgFileDoc} className="file-icon-type" alt="img-creative-solutions" />
                              )
                            default:
                              return (
                                <img src={imgFileDefault} className="file-icon-type" alt="img-creative-solutions" />
                              )
                          }
                        })()}
                      </div>
                        <div className="file-upload-info"><div className="file-upload-title">
                          <span className={`file-name m-0 ${data.invalid ? 'file-error' : ''}`}>{data.name}</span>
                        </div>
                          <div className="file-upload-size">({fileSize(data.size)})
                            {" "} {data.invalid && <span className='file-error-message'>({errorMessage})</span>}
                          </div>
                        </div>
                        <div className="file-upload-action">
                          <button type="button" className="btn btn-icon text-danger m-0" data-dismiss="modal" onClick={() => removeFile(data.name)}>
                            <i className="feather icon-trash-2 h6"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setSelectedFiles([]);
            handleCloseUp()
          }} >
            Close
          </Button>
          <Button type="submit" disabled={!(unsupportedFiles.length === 0 && validFiles.length)} className="btn btn-primary float-right m-0" onClick={() => uploadFiles()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <div role="dialog" aria-modal="true" className="fade upload-overlay modal show" tabIndex="-1"
        aria-labelledby="contained-modal-title-vcenter" ref={uploadModalRef}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={(() => closeUploadModal())}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
            </div>
            <div className="modal-body">
              <div className="progress-container">
                <span ref={uploadRef}></span>
                <div className="progress">
                  <div className="progress-bar" ref={progressRef}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </ >
  );
}

