
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Aux from "../../../hoc/_Aux";
import NavBar from '../../layout/UserLayout/NavBar';
import Navigation from '../../layout/UserLayout/Navigation';
import { MainNavUser } from "../../../components/header/MainNavUser";
import { FooterUser } from "../../../components/footer/FooterUser";
import { Loading } from "../../../components/loader/Loading";
import { MsgTimeOutError } from "../../../components/loader/MsgTimeOutError";
import { FormProfile } from "../../../components/form/FormProfile";
import { getKeyInStorage } from '../../../utils/Common';
import { userService } from '../../../services';

export const Profile = ({ location, history }) => {
  // State array variable to save and show data
  let title = "Profile";
  document.title = title + ' | SNHCPA';

  const [data, setData] = useState({
    userId: '',
    userFirstname: '',
    userLastname: '',
    birthday: null,
    userGender: '',
    userStatusName: '',
    userTypeName: '',
    userEmail: '',
    phone: '',
    userLast4SS: '',
    address: '',
    corporationName: ''
  });

  const user = getKeyInStorage('objectInfo');
  const idUser = user.userId
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getUserData = async () => {
      setError(null);
      setIsLoaded(true);
      try {
        const response = await userService.userData(idUser)
        const user = response.data.data
        const data = {};
        if (user.userId)
          data["userId"] = user.userId;

        if (user.userFirstname)
          data["userFirstname"] = user.userFirstname;

        if (user.userLastname)
          data["userLastname"] = user.userLastname;

        if (user.userBirthday) {
          var date = user.userBirthday;
          var replace = /-/g;
          var birthday = date.replace(replace, '/');
          data["birthday"] = new Date(birthday);
        }

        if (user.userGender)
          data["userGender"] = user.userGender;

        if (user.userStatus.userStatusName)
          data["userStatusName"] = user.userStatus.userStatusName;

        if (user.userType.userTypeName)
          data["userTypeName"] = user.userType.userTypeName;

        if (user.userLast4SS)
          data["userLast4SS"] = user.userLast4SS;

        if (user.userPhones[0].userPhone)
          data["phone"] = user.userPhones[0].userPhone;

        if (user.userEmails[0].userEmail)
          data["userEmail"] = user.userEmails[0].userEmail;

        if (user.userAdresses[0].userAddress)
          data["address"] = user.userAdresses[0].userAddress;

        if (user.userCorporations.length)
          data["corporationName"] = user.userCorporations[0].userCorporation.corporationName;

        setData(data);

      } catch (error) {
        setError(error);
        console.log(error);
      }
      setIsLoaded(false);
    }
    getUserData();
  }, [idUser]);

  return (
    <div>
      <MainNavUser />
      <Aux>
        <div className="row mx-0">
          <div className="col-sm-12 col-lg-auto p-0" >
            <Navigation />
            <NavBar />
          </div>

          <div className="col-9 mx-auto mt-5">
            <div className="d-flex justify-content-end" >
              <Button variant="link" className="btn btn-link p-0 ml-auto" onClick={() => { window.history.back(); }}><i className="feather icon-arrow-left mr-0"></i> Back</Button>
            </div>
            {isLoaded ? <Loading /> : error ?
              <MsgTimeOutError /> :
              <FormProfile data={data} setData={setData} />
            }
          </div>
        </div>
      </Aux>
      <FooterUser />
    </div >
  );
}

