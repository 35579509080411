import React from 'react';
import '../../assets/scss/style.scss';

export const FooterUser = () => {
  return (
    <footer bg="secondary" variant="light" className="bg-secondary text-white">
      <div className="container">
        <div className="row">
          <p className="col-12 my-3 text-center">
            &copy; Copyright {new Date().getFullYear()}, <b>Syed N. Haque, CPA & Associates, P.C.</b>| All right reserved
          </p>
        </div>
      </div>
    </footer>
  );
}