import React, { useEffect, useState } from 'react';
import { toolService } from '../../services'
import '../../assets/scss/style.scss';

export const MainNavInfo = () => {

  const [data, setData] = useState({
    email: '',
    phone: ''
  });

  useEffect(() => {
    let isComponentMounted = true;
    const infoContact = () => {
      toolService.getLayerSection('contact').then(response => {
        response.data.data.layerSections.forEach((section) => {
          const subsection = section.subSections[0];
          const info = {};
          subsection.subSectionExtraData.forEach((extradata) => {
            if (extradata.extraDataTitle === 'EMAIL') {
              info["email"] = extradata.extraDataParagraph;
            }
            if (extradata.extraDataTitle === 'PHONE') {
              info["phone"] = extradata.extraDataParagraph;
            }
          });
          if (isComponentMounted) {
            setData(info);
          }
        });
      }).catch(error => {
        console.log(error);
      });
    };
    infoContact();
    return () => {
      isComponentMounted = false;
    }
  }, [setData]);

  return (
    <div className="bg-white py-1">
      <div className="container d-flex justify-content-between">
        <span >
          <i className="fa fa-phone text-primary pr-1"></i>
          <strong>
            <a href={`tel:${data.phone}`} className="btn-link text-primary text-monospace h6">
              {data.phone}
            </a>
          </strong>

        </span>
        <div>
          <i className="feather icon-mail text-primary pr-1"></i>
          <strong>
            <a href={`mailto:${data.email}`} className="btn-link text-primary font-weight-bold h6">
              {data.email}
            </a>
          </strong>
        </div>
      </div>
    </div>
  );
}