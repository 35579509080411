import { apiWrapper } from '../helpers';
import { errorService, alertService } from '../services';

const baseUrl = `${process.env.REACT_APP_ADMIN_API}:8001/snhcpa/v1/users`;


// Enrollment
const enrollment = (data) => {
  return apiWrapper.post(`${baseUrl}/enrollment`, data)
}
// Enrollment Signup
const enrollmentSignup = (data) => {
  return apiWrapper.post(`${baseUrl}/enrollment/signup`, data)
}

// Users
const baseUsers = (data, { setSubmitting }) => {
  apiWrapper.put(`${baseUrl}`, data).then(response => {
    alertService.successSwel('Data successfully updated', 'Ok')
    setSubmitting(false);
  }).catch(error => {
    setSubmitting(false);
    if (error.response) {
      var rc = error.response.data.rc;
      var msg = error.response.data.msg;
      errorService.error(rc, msg)
    } else {
      alertService.warningSwel("Something went wrong. Please try again later.")
    }
  });
}

// Security Questions
const userQuestion = (data) => {
  return apiWrapper.put(`${baseUrl}/security/questions`, data)
}

// Assign
const assign = (data) => {
  return apiWrapper.put(`${baseUrl}/assign`, data)
}


/* ------------------ ASYNC ----------------------------- */
//All admin users
const allAdminUsers = () => {
  return apiWrapper.get(`${baseUrl}`)
}
//All SubAdmin users
const allSubAdminUsers = (id) => {
  return apiWrapper.get(`${baseUrl}?from=` + id)
}

//Assign user role
const assignUserRole = (role) => {
  return apiWrapper.get(`${baseUrl}?role=` + role)
}

//Get user security question
const emailSecSettings = (email) => {
  return apiWrapper.get(`${baseUrl}/email/` + email + `/security/questions`)
}

//Get user data
const userData = (id) => {
  return apiWrapper.get(`${baseUrl}/` + id)
}
//Get recent users admin
const recentUserAdmin = () => {
  return apiWrapper.get(`${baseUrl}/recent`)
}

//Get recent users subadmin
const recentUserSubAdmin = (id) => {
  return apiWrapper.get(`${baseUrl}/recent?from=` + id)
}

export const userService = {
  baseUsers,
  userQuestion,
  assign,
  enrollment,
  enrollmentSignup,
  allAdminUsers,
  allSubAdminUsers,
  assignUserRole,
  emailSecSettings,
  userData,
  recentUserAdmin,
  recentUserSubAdmin
};

