import React from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import { getSchema } from "../../config/Validations";
import { errorService, alertService, folderService } from '../../services'

export const MoveFile = ({ showMove, setShowMove, handleCloseMove, userFolder, data, setData, allFolders }) => {

  const validationSchemaMoveFile = getSchema(["receivedFolderId"]);

  function onSubmitMoveFile(values, { setSubmitting }) {
    folderService.move(values).then(response => {
      setData((prevState) => ({
        ...prevState,
        folders: [],
        filesFolder: {},
        files: []
      }));
      response.data.data.forEach((userData) => {
        const name = userData.userFolderName
        if (userData.userFolderId === data.actualFolderId) {
          setData((prevState) => ({
            ...prevState,
            filesFolder: {
              ...prevState,
              userFolderFiles: userData.userFolderFiles
            }
          }))
        }
        if (name !== 'Default folder') {
          setData((prevState) => ({
            ...prevState,
            folders: [
              ...prevState.folders,
              userData
            ]
          }))
        } else {
          setData((prevState) => ({
            ...prevState,
            files: userData
          }))
        }
      })
      setShowMove(false)
      alertService.successSwel('Data successfully updated', 'Ok')
      setSubmitting(false);
    }).catch(error => {
      setSubmitting(false);
      if (error.response) {
        var rc = error.response.data.rc;
        var msg = error.response.data.msg;
        errorService.error(rc, msg)
      } else {
        alertService.warningSwel("Something went wrong. Please try again later.")
      }
    });
  }
  return (
    <>
      <Modal show={showMove} onHide={handleCloseMove}>
        <Formik initialValues={userFolder} validationSchema={validationSchemaMoveFile} onSubmit={onSubmitMoveFile} enableReinitialize>
          {({ handleSubmit, touched, errors, isSubmitting }) => (
            <Form noValidate onSubmit={handleSubmit} >
              <Modal.Header closeButton>
                <Modal.Title> Move file</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="flex-column align-content-center">
                  <label >Move </label>
                  <Field as="select" name='receivedFolderId' className={'form-control' + (errors.receivedFolderId && touched.receivedFolderId ? ' is-invalid' : '')} disabled={isSubmitting}>
                    <option value="">Select folder </option>
                    {allFolders.map((element, index) => {
                      return (
                        <option key={index} value={element.idFolder}>
                          {(data.actualFolderId !== element.idFolder) ?
                            (element.name === 'Default folder' ? 'Root' : element.name) : ''}
                        </option>
                      )
                    })}
                  </Field>
                  <ErrorMessage name='receivedFolderId' component="div" className="invalid-feedback" />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseMove} disabled={isSubmitting}>
                  Close
                </Button>
                <Button type="submit" disabled={isSubmitting} className="btn btn-primary float-right m-0">
                  {isSubmitting ? 'Loading...' : 'Move'}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </ >
  );
}

