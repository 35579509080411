import React, { useState, useEffect, useRef } from 'react';
import * as yup from "yup"
import sha256 from "js-sha256";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import ReactLoading from 'react-loading';
import { NavLink } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Aux from "../../hoc/_Aux";
import Breadcrumb from "../../pages/layout/AdminLayout/Breadcrumb";
import { MainNavUser } from "../../components/header/MainNavUser";
import { FooterUser } from "../../components/footer/FooterUser";
import { ErrorView } from "../../pages/error/ErrorView";
import { setTokenOtp, getKeyInStorage } from '../../utils/Common';
import { sessionService, toolService, errorService, alertService, securityService } from '../../services'

export const Login = ({ history }) => {
  let title = "Client portal";
  document.title = title + ' | SNHCPA';

  const initialValues = {
    email: '',
    password: '',
    recaptcha: '',
  };
  const [credentials, setCredentials] = useState('6LcUK9gdAAAAAOv59stvlgQWC5ezZA7qYK-XB85U')
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Email is invalid")
      .required("Required field"),
    password: yup
      .string()
      .min(8, "The field must be at least 8 characters long")
      .max(15, "The field must be 15 characters or less ")
      .required("Required field"),
    recaptcha: yup
      .string()
      .nullable("Verification expired. Check the checkbox again.")
      .required(),
  });

  const recaptchaRef = useRef(null);

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const tokenOtp = (flow, privateToken, userValid) => {
    setTokenOtp('route', flow);
    setTokenOtp('tokenValid', privateToken);
    setTokenOtp('userValid', userValid);
  };

  function onSubmit({ email, password }, { setSubmitting }) {
    const data = {
      email: email,
      password: sha256(password),
    };
    // CALL TO API
    sessionService.login(data)
      .then(() => {
        const dataOtp = {
          email: email,
          flow: 'LOGIN'
        };
        // CALL TO API
        toolService.sendOtp(dataOtp)
          .then(response => {
            const privateToken = response.data.data.privateToken;
            const userEmail = email;
            const flow = 'LOGIN';
            tokenOtp(flow, privateToken, userEmail)
            alertService.successConfirmOutsideSwel()
            history.push('/otp');
          })
          .catch(error => {
            setSubmitting(false);
            if (error.response) {
              var rc = error.response.data.rc;
              var msg = error.response.data.msg;
              errorService.error(rc, msg)
            } else {
              alertService.warningSwel("Something went wrong. Please try again later.")
            }
          })
      })
      .catch(error => {
        setSubmitting(false);
        if (error.response) {
          var rc = error.response.data.rc;
          var msg = error.response.data.msg;
          if (rc === '-2') {
            const dataOtp = {
              email: email,
              flow: 'VALIDATE_USER'
            };
            Swal.fire({
              icon: 'info',
              title: 'Information',
              text: msg,
              confirmButtonText: 'Validate',
              confirmButtonColor: '#112349',
              focusConfirm: false,
              showLoaderOnConfirm: true,
              // CALL TO API
              preConfirm: () => {
                return toolService.sendOtp(dataOtp)
                  .then(response => {
                    const privateToken = response.data.data.privateToken;
                    const userEmail = email;
                    const flow = 'VALIDATE_USER';
                    tokenOtp(flow, privateToken, userEmail)
                    alertService.successConfirmSwel()
                    history.push('/otp');
                  })
                  .catch(error => {
                    setSubmitting(false);
                    if (error.response) {
                      var rc = error.response.data.rc;
                      var msg = error.response.data.msg;
                      errorService.error(rc, msg)
                    } else {
                      alertService.warningSwel("Something went wrong. Please try again later.")
                    }
                  })
              },
              allowOutsideClick: () => !Swal.isLoading()
            })
          } else {
            errorService.error(rc, msg)
          }
        } else {
          alertService.warningSwel("Something went wrong. Please try again later.")
        }
      });
  }

  useEffect(() => {

    const getCredentials = async () => {
      setError(null);
      setIsLoaded(true);
      try {
        const response = await securityService.getRecaptcha()
        setCredentials(response.data.data[0].recaptchaKey);
      } catch (error) {
        setError(error);
        console.log(error);
      }
      setIsLoaded(false);
    }
    getCredentials();

    if (getKeyInStorage('tokenUserValid') && getKeyInStorage('objectInfo') && getKeyInStorage('userValid')) {
      return history.push('/');
    }


  }, [history])
  if (isLoaded) {
    return (
      <div className="loader">
        <ReactLoading type={'bubbles'} color={'#112349'} height={100} width={100} className="d-flex mx-auto" />
        <h5>Loading...</h5>
      </div>
    )
  } else {
    if (error) {
      return (<ErrorView />)
    } else {

      return (
        <Aux>
          <Breadcrumb />
          <MainNavUser />
          <div className="auth-wrapper">
            <div className="auth-content col-7">
              <div className="auth-bg">
                <span className="r" />
                <span className="r s" />
                <span className="r s" />
                <span className="r" />
              </div>
              <div className="card" >
                <div className="row" data-aos="zoom-out">
                  <div className="col-6 bg-c-blue">

                    <div className="card-body h-100 d-flex align-items-center text-white">
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-4 text-center">
                            <i className="feather icon-info h2" />
                          </div>
                        </div>
                        <div className="col-12">
                          <p>Our client portal is user friendly, easy and simple; but highly secured for sharing information with us. This system provides a wide range of facilities to our clients for sharing their documents as well as having access to the information delivered and to review the documents in their own accounts. The portal facility is specifically designed to help our clients for sharing information with them through a two-way authentication verification process under a safe system environment.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="card-body text-center">
                      <div className="mb-4">
                        <i className="feather icon-unlock auth-icon" />
                      </div>
                      <h3 className="mb-4">Client portal</h3>
                      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                        {({ errors, touched, isSubmitting, setFieldValue, setSubmitting }) => (
                          <Form>
                            <div className="form-group text-left">
                              <label>Email</label>
                              <Field name="email" type="email" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} placeholder="example@domian.com" disabled={isSubmitting} />
                              <ErrorMessage name="email" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group text-left">
                              <label>Password</label>
                              <div className="input-group text-left">
                                <Field name="password" type={passwordShown ? "text" : "password"} className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} placeholder="●●●●●●●" disabled={isSubmitting} />
                                <div className="input-group-append">
                                  <span className="input-group-text p-0 pointer">
                                    <i className={'feather px-3 py-2 ' + (passwordShown ? ' icon-eye-off' : 'icon-eye')} onClick={togglePasswordVisiblity} />
                                  </span>
                                </div>
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                              </div>
                            </div>
                            <div className="mt-2 mb-4 recaptcha text-capitalize d-flex flex-column align-items-center">
                              <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={credentials}
                                onChange={(value) => {
                                  setFieldValue("recaptcha", value);
                                  setSubmitting(false);
                                }}
                              />
                              <ErrorMessage name="recaptcha" component="div" className="invalid-feedback-general" />
                            </div>
                            <div className="form-row mx-auto">
                              <div className="col">
                                <button type="submit" disabled={isSubmitting} className="btn btn-primary mb-4">
                                  {isSubmitting ? 'Loading...' : 'Login'}
                                </button>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                      <p className="mb-2 text-muted">Forgot password? <NavLink to="/forgot-password">Recover</NavLink></p>
                      <p className="mb-0 text-muted">Don’t have an account yet? <NavLink to="/sign-up">Sign Up</NavLink></p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <FooterUser />
        </Aux>
      );
    }
  }
}