import React from 'react'
import { Button } from 'react-bootstrap';

export const BreadcrumbsFiles = (props) => {
  const { data, setData, showFiles, setShowFiles, handleShow, handleShowUp } = props

  const goBack = () => {
    setShowFiles(false)
    setData((prevState) => ({
      ...prevState,
      filesFolder: {},
      actualFolderId: data.files.userFolderId,
      actualFolderName: data.files.userFolderName
    }));
  };

  return (
    <div className="d-flex justify-content-between align-items-center position-relative">
      <div className="d-flex align-items-center">
        {!showFiles ? (
          <h3>Files</h3>
        ) : (
          <>
            <Button variant="link" className="btn btn-link m-0 p-0" onClick={() => { goBack() }}><i className="feather icon-folder mr-0"></i> Root</Button>
            <span className="mx-2"> / </span>
            <span> <i className="feather icon-folder"></i> {data.actualFolderName}</span>
          </>
        )}
      </div>
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center flex-grow-1 d-lg-none">
          {/* <i className="feather icon-search"></i>
          <input type="text" className="form-control border-0 form-focus-none bg-transparent" placeholder="Search files, folders" /> */}
        </div>
        <div className="d-flex align-items-center d-lg-none">
          <Button variant="light" className="btn-sm h-100 mx-2 mb-0"
            onClick={() => {
              setData((prevState) => ({
                ...prevState,
                typeModal: 'Create',
                form: ''
              })); handleShow()
            }}><i className="feather icon-folder mr-0"></i></Button>
          <Button variant="primary" className="btn-sm h-100 mx-2 mb-0" onClick={() => { handleShowUp() }}><i className="feather icon-upload mr-0"></i> </Button>
        </div>

        {showFiles ? (
          <div className="d-flex align-items-center">
            <Button variant="link" className="btn btn-link h-100 mx-2 mb-0" onClick={() => { goBack() }}><i className="feather icon-arrow-left mr-0"></i> Back</Button>
          </div>
        ) : ''}
      </div>
    </div>
  )
}