import React from 'react';
import { Route } from 'react-router-dom';
import { getKeyInStorage } from './Common';
import { Error404View } from '../pages/error/Error404View';

// handle the private routes
export const PrivateRoute = ({ logged, component: Component, roles, ...rest }) => {

  const comp = Component.name;
  const route = getKeyInStorage('route');
  const user = getKeyInStorage('objectInfo');
  const roleUser = user.userRole
  const validateRole = roles.includes(roleUser)
  return (
    <Route
      {...rest}
      render={(props) =>
        logged ?
          getKeyInStorage('tokenUserValid') && logged && (roles && validateRole)
            ? <Component {...props} />
            : <Route path="*" exact={true} component={Error404View} />
          :
          comp === 'ResetPassword' ?
            getKeyInStorage('tokenValid') && route === 'RECOVER_PASSWORD'
              ? <Component {...props} />
              : <Route path="*" exact={true} component={Error404View} />
            :
            getKeyInStorage('tokenValid') && (route === 'RECOVER_PASSWORD' || route === 'VALIDATE_USER' || route === 'LOGIN')
              ? <Component {...props} />
              : <Route path="*" exact={true} component={Error404View} />
      }
    />
  )
}