import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";
import AOS from "aos";
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import "aos/dist/aos.css";
import Loader from "./pages/layout/Loader";
import { App } from './pages/index';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';
import config from './router/Config';

const token = localStorage.getItem('token')
const store = createStore(reducer);
axios.defaults.baseURL = process.env.REACT_APP_ADMIN_API+":";
axios.defaults.timeout = 60000;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.headers.delete['Content-Type'] = 'application/json';
axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Authorization'] = "Bearer " + token;
AOS.init({
  duration: 800,
  easing: "ease-in-out",
  once: true
});


const app = (
  <Provider store={store}>
    <Suspense fallback={<Loader />}>
      <BrowserRouter basename={config.basename}>
        {/* basename="/datta-able" */}
        <App />
      </BrowserRouter>
    </Suspense>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
