import React, { useState } from 'react';
import OtpInput from "react-otp-input";
import { Formik, Form } from 'formik';
import Aux from "../../hoc/_Aux";
import Breadcrumb from "../../pages/layout/AdminLayout/Breadcrumb";
import { getKeyInStorage, removeKeyInStorage, setTokenOtp, setUserSession } from '../../utils/Common';
import { toolService, errorService, alertService } from '../../services'
import '../../assets/scss/style.scss';

export const OneTimePassword = ({ history }) => {

  let title = "Code";
  document.title = title + ' | SNHCPA';

  const email = getKeyInStorage('userValid');
  const [otp, setOtp] = useState("");
  const [state, setState] = useState({
    isDisabled: false,
    loading: false,
    resend: false
  });

  const tokenOtp = (flow, privateToken, userValid) => {
    setTokenOtp('route', flow);
    setTokenOtp('tokenValid', privateToken);
    setTokenOtp('userValid', userValid);
  };

  const remove = () => {
    removeKeyInStorage('route');
    removeKeyInStorage('tokenValid');
  };

  const clearOtp = () => {
    setOtp(otp ? '' : '');
  };

  const handleOtpChange = (otp) => {
    setOtp(otp)
  }

  const onSubmitOtp = () => {
    setState({ loading: true });
    const data = {
      tokenOtp: otp.toUpperCase(),
      email: getKeyInStorage('userValid')
    }
    // CALL TO API
    toolService.validateOtp(data).then(response => {
      var route = getKeyInStorage('route');
      switch (route) {
        case "LOGIN":
        case "VALIDATE_USER":
          const token = response.data.data.sessionInfo.sessionToken;
          const user = response.data.data.userInfo;
          const role = response.data.data.userInfo.userRole;
          setUserSession('tokenUserValid', token);
          setUserSession('objectInfo', user);
          alertService.successSwel('Login success', 'Ok')
          remove()
          if (role === 'USER') {
            history.push('/dashboard');
          } else {
            history.push('/admin/dashboard');
          }
          break
        case "RECOVER_PASSWORD":
          alertService.successSwel('Your password has been updated successfully', 'Ok')
          history.push('/reset-password');
          break
        default:
          alertService.successSwel('Your user has been successfully validated', 'Ok')
          remove()
          history.push('/client-portal');
          break
      }
    }).catch(error => {
      setState({
        loading: false,
      });
      if (error.response) {
        var rc = error.response.data.rc;
        var msg = error.response.data.msg;
        if (rc === '-5' || rc === '-8') {
          alertService.errorSwel(error.response.data.msg, 'Ok')
          remove()
          history.push('/client-portal');
        } else {
          errorService.error(rc, msg)
        }
      } else {
        alertService.warningSwel("Something went wrong. Please try again later.")
      }
    });
  }

  //Resend otp to email
  function nonReceivedCode() {
    setState({ resend: true });
    const data = {
      email: email,
      flow: getKeyInStorage('route')
    };

    // CALL TO API
    toolService.sendOtp(data).then(response => {
      const privateToken = response.data.data.privateToken;
      const userEmail = email;
      const flow = getKeyInStorage('route');
      tokenOtp(flow, privateToken, userEmail)
      alertService.successConfirmSwel()
      setState({ resend: false });
    })
      .catch(error => {
        setState({ resend: false });
        if (error.response) {
          var rc = error.response.data.rc;
          var msg = error.response.data.msg;
          errorService.error(rc, msg)
        } else {
          alertService.warningSwel("Something went wrong. Please try again later.")
        }
      });
  }

  return (
    <Aux>
      <Breadcrumb />
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          <div className="card" data-aos="zoom-out">
            <div className="card-body text-center">
              <div className="mb-4">
                <i className="feather icon-lock auth-icon" />
              </div>
              <h3 className="py-4">Verification Code</h3>
              <p className="pb-4">Please enter the verification code sent to your email</p>
              <Formik initialValues={{ otp: '' }} onSubmit={onSubmitOtp}>
                {() => (
                  <Form>
                    <div className="row d-flex align-items-center pb-4">
                      <div className="col-12">
                        <OtpInput
                          value={otp.toUpperCase()}
                          onChange={handleOtpChange}
                          numInputs={5}
                          separator={
                            <span>
                              <strong>.</strong>
                            </span>
                          }
                          inputStyle={{
                            width: "100%",
                            height: "2.6rem",
                            margin: "0 .4rem",
                            fontSize: "1rem",
                            borderRadius: 4,
                            border: "1px solid rgba(0,0,0,0.3)"
                          }}
                          isDisabled={state.loading}
                        />
                      </div>
                    </div>
                    <div className="form-row mt-4 mb-2">
                      <div className="col">
                        <button type="button" disabled={state.isDisabled || otp.trim() === '' || state.loading} className="btn btn-secondary mb-4" onClick={clearOtp}>
                          Clear
                        </button>
                        <button type="submit" disabled={otp.length < 5 || state.loading}
                          className="btn btn-primary mb-4">
                          {state.loading ? 'Loading...' : 'Send'}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              <p className="mb-2 text-muted">If you not received your code, <button type="button" className="text-primary btn-link btn text-decoration-none p-0" onClick={nonReceivedCode} disabled={state.resend} >click here to resend code</button></p>
            </div>
          </div>
        </div>
      </div>
    </Aux>
  );
}

export default OneTimePassword;