import React from "react";
import { Form } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik'

function RadioButtons(props) {
  const { label, name, attr, options } = props
  return (
    <Form.Group className="form-group text-nowrap col-12 text-left mb-0 pt-4 mt-2">
      <label className="mr-2">{label} </label>
      <div className="form-check-inline">
        <Field name={name} >
          {({ field }) => {
            return options.map(option => {
              return (
                <React.Fragment key={option.key}>
                  <div className="custom-control custom-radio radio-inline">
                    <input
                      type='radio'
                      id={option.value}
                      {...field}
                      value={option.value}
                      checked={field.value === option.value}
                      className={attr}
                    />
                    <label htmlFor={option.value} className="custom-control-label pointer">{option.text}</label>
                  </div>
                </React.Fragment>
              )
            })
          }}
        </Field>
      </div>
      <ErrorMessage component="div" name={name} className="invalid-feedback-general mt-0" />
    </Form.Group>
  )
}
export default RadioButtons;
