export default {
  items: [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/dashboard',
          icon: 'feather icon-home',
        },
        {
          id: 'my-documents',
          title: 'My documents',
          type: 'item',
          url: '/my-documents',
          icon: 'feather icon-folder',
        },
        {
          id: 'profile',
          title: 'Profile',
          type: 'item',
          url: '/profile',
          icon: 'feather icon-user',
        },
        {
          id: 'security-settings',
          title: 'Security settings',
          type: 'item',
          url: '/security-settings',
          icon: 'feather icon-settings',
        }
      ]
    }
  ]
}